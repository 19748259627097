import React, { useState, useRef, useEffect } from "react";
import MODCanvas from "../../MODCanvas";
import axios from "axios";

import styles from "../Settings.module.css";
import { Button, Checkbox, Input, Select } from "@chakra-ui/react";

function MissingObjectDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState();
  const [whiteLightAlarm, setWhiteLightAlarm] = useState();
  const [appMessagePush, setAppMessagePush] = useState();
  const [rtmpPush, setRtmpPush] = useState();
  const [ftpPush, setFtpPush] = useState();
  const [direction, setDirection] = useState("");
  const [RepeatAlarmTime, setRepeatAlarmTime] = useState();
  const [Sensitivity, setSensitivity] = useState();
  const [MinDuration, setMinDuration] = useState();
  const [error, setError] = useState(null);
  const [Action, setAction] = useState("");
  const [DetectRegion, setDetectRegion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getMissingObjectDetection`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsEnabled(response.data.Enabled);
        setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled);
        setFtpPush(response.data.AlarmOut.FtpPush.Enabled);
        setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled);
        setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled);
        setAppMessagePush(response.data.AlarmOut.AppPush.Enabled);
        setMinDuration(response.data.Duration);
        setSensitivity(response.data.Sensitivity);
        setDetectRegion(response.data.DetectAera);
        console.log(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/setMissingObjectDetection`,
        {
          Enabled: isEnabled,
          DetectRegion: DetectRegion,
          MinDuration: MinDuration,
          Sensitivity: Sensitivity,
          AlarmOut: {
            AudioAlert: {
              Enabled: alarmSound,
            },
            LightAlert: {
              Enabled: whiteLightAlarm,
            },
            AppPush: {
              Enabled: appMessagePush,
            },
            RtmpPush: {
              Enabled: rtmpPush,
            },
            FtpPush: {
              Enabled: ftpPush,
            },
            Email: {
              Enabled: false,
            },
            gat1400: {
              Enabled: false,
            },
          },
        },
        {
          params: { deviceId: deviceId },
        }
      );

      console.log("Settings saved", response.data);
    } catch (error) {
      console.error("Error saving settings", error);
    }
  };

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };

  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (region, direction) => {
    setDetectRegion(region);
    setDirection(direction);
  };

  return (
    <div className={styles.notify}>

      <div className={styles.checkboxContainer} style={{ color: '#7F56D9' }}>
        <label style={{ fontSize: "18px", fontWeight: "bold" }}>Missing Object Detection</label>
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="isEnabled" style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enabled
        </label>
        <Checkbox
          isChecked={isEnabled}
          id="isEnabled"
          onChange={(e) => setIsEnabled(e.target.checked)}
          style={{
            borderColor:'black',
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="alarmSound" style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable Alarm Sound
        </label>
        <Checkbox
          isChecked={alarmSound}
          id="alarmSound"
          onChange={(e) => setAlarmSound(e.target.checked)}
          style={{
            borderColor:'black',
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="whiteLightAlarm" style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable White Light alarm sound
        </label>
        <Checkbox
          isChecked={whiteLightAlarm}
          id="whiteLightAlarm"
          onChange={(e) => setWhiteLightAlarm(e.target.checked)}
          style={{

            borderColor:'black',
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="appMessagePush" style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable app message push
        </label>
        <Checkbox
          isChecked={appMessagePush}
          id="appMessagePush"
          onChange={(e) => setAppMessagePush(e.target.checked)}
          style={{

            borderColor:'black',

            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="rtmpPush" style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable RTMP push
        </label>
        <Checkbox
          isChecked={rtmpPush}
          id="rtmpPush"
          onChange={(e) => setRtmpPush(e.target.checked)}
          style={{
            borderColor:'black',
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="ftpPush" style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable FTP push
        </label>
        <Checkbox
          isChecked={ftpPush}
          id="ftpPush"
          onChange={(e) => setFtpPush(e.target.checked)}
          style={{
            borderColor:'black',
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="" style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Minimum Duration(s)
        </label>
        <Input
          type="text"
          placeholder="MinDuration"
          style={{
            width:'100px',
            borderColor:'black',
            padding: '3px',
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{display:'flex'}}>
        <label style={{ marginRight: "16px", fontWeight: "bold" }}>Sensitivity Level</label>
        <Select
          width="150px"
          value={Sensitivity}
          onChange={(e) => setSensitivity(Number(e.target.value))}
          style={{
            padding: "4px 6px",
            // border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "14px",
            color: "#333",
            backgroundColor: "#fff",
            // appearance: "none", // Remove the default arrow style
            // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          }}
        >
          {Array.from({ length: 11 }, (_, i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </Select>
      </div>

      <div >
        <Button colorScheme="teal" onClick={openCanvasModal} style={{
          backgroundColor: "#88ffcc",
          color: "black",
          padding: "2px 6px",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontWeight: "bold"
        }} >
          Open Canvas
        </Button>
      </div>

      <MODCanvas
        isOpen={isCanvasModalOpen}
        onClose={closeCanvasModal}
        onCanvasData={handleCanvasData}
        existingCoordinates={DetectRegion}
        deviceId={deviceId}
      />

      <div>
        <Button onClick={handleSave} style={{
          backgroundColor: "#7F56D9",
          color: "white",
          padding: "2px 6px",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontWeight: "bold"
        }}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default MissingObjectDetection;
