import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';

function Image_Setup({ deviceId, onSave }) {
  const [contrast, setContrast] = useState(50);
  const [brightness, setBrightness] = useState(50);
  const [saturation, setSaturation] = useState(50);
  const [hue, setHue] = useState(50);
  const [sharpen, setSharpen] = useState(50);
  const [flipChecked, setFlipChecked] = useState(false);
  const [mirrorChecked, setMirrorChecked] = useState(false);
  const [error, setError] = useState(null);

  // Fetch the current video settings when the component mounts
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/p2p/video-settings`, {
          params: {
            deviceid: deviceId,
          },
        });
        const settings = response.data;
        setContrast(settings.contrastLevel);
        setBrightness(settings.brightnessLevel);
        setSaturation(settings.saturationLevel);
        setHue(settings.hueLevel);
        setSharpen(settings.sharpnessLevel);
        setFlipChecked(settings.flipEnabled);
        setMirrorChecked(settings.mirrorEnabled);
      } catch (error) {
        console.error('Error fetching settings:', error);
        setError(error);
      }
    };
    fetchSettings();
  }, [deviceId]);

  const updateVideoSettings = async (updatedSettings) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/p2p/postVideo-settings`,
        updatedSettings,
        {
          params: {
            deviceid: deviceId,
          },
        }
      );
      console.log('Settings updated:', response.data);
    } catch (error) {
      console.error('Error updating settings:', error);
      setError(error);
    }
  };

  const handleSettingChange = (key, value) => {
    const updatedSettings = {
      contrastLevel: key === 'contrast' ? value : contrast,
      brightnessLevel: key === 'brightness' ? value : brightness,
      saturationLevel: key === 'saturation' ? value : saturation,
      hueLevel: key === 'hue' ? value : hue,
      sharpnessLevel: key === 'sharpen' ? value : sharpen,
      flipEnabled: key === 'flipChecked' ? value : flipChecked,
      mirrorEnabled: key === 'mirrorChecked' ? value : mirrorChecked,
    };

    // Update the settings in state
    if (key === 'contrast') setContrast(value);
    if (key === 'brightness') setBrightness(value);
    if (key === 'saturation') setSaturation(value);
    if (key === 'hue') setHue(value);
    if (key === 'sharpen') setSharpen(value);
    if (key === 'flipChecked') setFlipChecked(value);
    if (key === 'mirrorChecked') setMirrorChecked(value);

    // Send updated settings to the server
    updateVideoSettings(updatedSettings);
  };

  // Define the handleSave function
  const handleSave = useCallback(() => {
    const settingsToSave = {
      contrastLevel: contrast,
      brightnessLevel: brightness,
      saturationLevel: saturation,
      hueLevel: hue,
      sharpnessLevel: sharpen,
      flipEnabled: flipChecked,
      mirrorEnabled: mirrorChecked,
    };
    updateVideoSettings(settingsToSave);
  }, [contrast, brightness, saturation, hue, sharpen, flipChecked, mirrorChecked]);

  // Save the settings when the component is ready
  useEffect(() => {
    if (onSave) {
      onSave(handleSave);
    }
  }, [onSave, handleSave]);

  return (
    <div className={styles.notify}>
  <div
        className={styles.checkboxContainer}
        style={{marginBottom: "16px", color: "#7F56D9" }}
      >
        <label style={{ fontSize: "18px", fontWeight: "bold" }}>
          Image Setting
        </label>
      
      </div>

      <div className={styles.progressbar} style={{ display: "flex", alignItems: "center" }}>
        <p style={{flex:'0 0 150px', fontSize: "18px", fontWeight: "bold" }}>Contrast</p>
        <input
          type="range"
          min="0"
          max="100"
          value={contrast}
          onChange={(e) => handleSettingChange('contrast', parseInt(e.target.value, 10))}
        />
      </div>

      <div className={styles.progressbar} style={{ display: "flex", alignItems: "center" }}>
        <p style={{flex:'0 0 150px', fontSize: "18px", fontWeight: "bold" }}>Brightness</p>
        <input
          type="range"
          min="0"
          max="100"
          value={brightness}
          onChange={(e) => handleSettingChange('brightness', parseInt(e.target.value, 10))}
        />
      </div>

      <div className={styles.progressbar} style={{ display: "flex", alignItems: "center" }}>
        <p style={{flex:'0 0 150px', fontSize: "18px", fontWeight: "bold" }}>Saturation</p>
        <input
          type="range"
          min="0"
          max="100"
          value={saturation}
          onChange={(e) => handleSettingChange('saturation', parseInt(e.target.value, 10))}
        />
      </div>

      <div className={styles.progressbar} style={{ display: "flex", alignItems: "center" }}>
        <p style={{flex:'0 0 150px', fontSize: "18px", fontWeight: "bold" }}>Hue</p>
        <input
          type="range"
          min="0"
          max="100"
          value={hue}
          onChange={(e) => handleSettingChange('hue', parseInt(e.target.value, 10))}
        />
      </div>

      <div className={styles.progressbar} style={{ display: "flex", alignItems: "center" }}>
        <p style={{flex:'0 0 150px', fontSize: "18px", fontWeight: "bold" }}>Sharpen</p>
        <input
          type="range"
          min="0"
          max="100"
          value={sharpen}
          onChange={(e) => handleSettingChange('sharpen', parseInt(e.target.value, 10))}
        />
      </div>

      <div className={styles.checkboxContainer} style={{ display: "flex", alignItems: "center" }}>
        <p style={{flex:'0 0 150px', fontSize: "18px", fontWeight: "bold" }}>Flip</p>
        <input
          type="checkbox"
          checked={flipChecked}
          onChange={(e) => handleSettingChange('flipChecked', e.target.checked)}
        />
      </div>

      <div className={styles.checkboxContainer} style={{ display: "flex", alignItems: "center" }}>
        <p  style={{ flex:'0 0 150px',fontSize: "18px", fontWeight: "bold" }}>Mirror</p>
        <input
          type="checkbox"
          checked={mirrorChecked}
          onChange={(e) => handleSettingChange('mirrorChecked', e.target.checked)}
        />
      </div>

      {error && <p className={styles.error}>Error: {error.message}</p>}
    </div>
  );
}

export default Image_Setup;
