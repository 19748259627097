import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import styles from './SignUpPage.module.css';
import { signup, verify } from '../../services/auth';
import { PinInput, PinInputField, useToast } from '@chakra-ui/react';

Modal.setAppElement('#root');

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOTP] = useState(['', '', '', '', '', '', '', '', '', '', '', '']);
  const [isOtpSubmitted, setIsOtpSubmitted] = useState(false);
  const toast = useToast();
  const showToast = (msg, status1) => {
    toast({
      description: msg,
      status: status1,
      duration: 2000,
      position: 'bottom-left',
      isClosable: false
    })
  }
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleSendOtp = async (event) => {
    if (!validatePassword(password)) {
      event.preventDefault();
      setErrorMessage('Password must be at least 8 characters, with at least one uppercase letter (A-Z), one lowercase letter (a-z), one number (0-9), and one special character (@/#$%^&+=).');
      return;
    }
    // Prevent form submission
    if (rememberMe) {
      localStorage.setItem('rememberMe', true);
      localStorage.setItem('email', email);
    } else {
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('email');
    }

    try {
      setErrorMessage('');
      setIsLoading(true); // Show the loader

      // Call the signup function and pass the email and password
      const signupResult = await signup(email, password);

      // Store email and password in localStorage (this might have security implications, consider alternatives)
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);

      if (signupResult.success) {
        setModalOpen(true)

        setIsLoading(false);
        await handleSendOtp(); // Trigger OTP logic
        showToast("OTP sent Successfully", 'success');
      } else {
        setIsLoading(false);
        setErrorMessage(signupResult.message);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Failed to sign up'); // Handle generic error message
      console.error('Error:', error);
    }
  };

  const handleVerifyOtp = async () => {
    const emails = localStorage.getItem('email');
    const otpValue = otp.join('');
    console.log('OTP submitted:', otpValue);
    if (!otp) {
      alert('First enter your OTP then verify');
      return;
    }
    try {
      setErrorMessage('');
      setIsLoading(true); // Show the loader

      // Call the verify function and pass the email and OTP value
      const verifyResult = await verify(emails, otpValue);

      if (verifyResult.message === 'Email verification successfully') {
        setTimeout(() => {
          // Reload the page to go back to the login page
          window.location.reload();
        }, 2000);
      } else {
        setIsLoading(false);
        setErrorMessage(verifyResult.message); // Display the error message from the API response
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Failed to verify OTP'); // Handle generic error message
      console.error('Error:', error);
    }

    // Redirect to dashboard or another page upon successful verification
    navigate('/');
  };

  const handleSignIn = (e) => {
    e.preventDefault(); // Prevent form submission
    navigate('/');
  };

  const handleChange = (e, index) => {
    if (!isOtpSubmitted) {
      const value = e.target.value.trim(); // Remove leading/trailing spaces
      const updatedOTP = [...otp];
      updatedOTP[index] = value;
      setOTP(updatedOTP);
    } else {
      setOTP(['', '', '', '', '', '', '', '', '', '', '', '']);
      setIsOtpSubmitted(false);
    }
  };
  return (
    <form className={styles.loginForm}>
      <div className={styles.inputGroup}>
        <label htmlFor="email" className={styles.label}>Email</label>
        <input
          type="email"
          id="email"
          className={styles.input}
          placeholder="Enter your email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className={styles.inputGroup}>
        <label htmlFor="password" className={styles.label}>Password</label>
        <input
          type="password"
          id="password"
          className={styles.input}
          placeholder="••••••••"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {/* <div className={styles.inputGroup}>
        <label htmlFor="phoneNumber" className={styles.label}>Phone Number</label>
        <input
          type="text"
          id="phoneNumber"
          className={styles.input}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
      </div> */}
      <div className={styles.formOptions}>
        <label className={styles.rememberMe}>
          {/* <input isChecked={rememberMe} onChange={() => setRememberMe(!rememberMe)} type="checkbox" /> */}
          {/* <span>Remember for 30 days</span> */}
        </label>
        <button type="button" className={styles.forgotPassword}>Forgot password</button>
      </div>
      {errorMessage && (
        <text style={{ color: 'red' }} fontSize="md" mt={2}>
          {errorMessage}
        </text>
      )}
      <button type="button" className={styles.submitButton} onClick={handleSendOtp}>Send an OTP</button>
      {/* <button type="button" className={styles.submitButton} onClick={handleSignIn}>Register</button> */}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        className={styles.modalContent}
        overlayClassName={styles.ReactModalOverlay}
        contentLabel="OTP Modal"
      >
        <h2>Enter OTP</h2>
        <PinInput size="sm" variant="filled" placeholder='*'>
          {otp.map((digit, index) => (
            <PinInputField
              key={index}
              value={digit}  // Set the value of the field to the corresponding digit in the OTP array
              onChange={(value) => handleChange(value, index)}  // Handle changes in the OTP field
              isLast={index === otp.length - 1}
            />
          ))}
        </PinInput>
        <div id='btngrp'>
          <button onClick={handleVerifyOtp} className={styles.buttonVerify}>Verify OTP</button>
          <button onClick={() => setModalOpen(false)} className={styles.buttonClose}>Close</button>
        </div>
      </Modal>
    </form>
  );
}

export default LoginForm;
