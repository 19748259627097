import React, { useState,useRef } from 'react';
import styles from './Settings.module.css';
import { IoSettingsOutline, IoWifi } from "react-icons/io5";
import { LuBadgeHelp } from "react-icons/lu";
import { MdContentCopy } from "react-icons/md";
import General from './General';
import Media from './Media-Setting/Media';
import EdgeAi from './EdgeAi';
import Network from "./Network";
import SystemSetting from "./SystemSetting";

function Settings({ device, onClose }) {
  


  const [activeTab, setActiveTab] = useState("General");
  const [selectedMedia, setSelectedMedia] = useState("Video Setting"); // State to track selected item

  // Handler for clicking a list item
  const handleItemClick = (mediaType) => {
    setSelectedMedia(mediaType); // Update state with clicked item
  };
  const [selectedAi, setSelectedAi] = useState("Motion Detection"); // State to track selected item

  const [selectedNetwork, setSelectedNetwork] = useState("Local Setup"); // State to track selected item



  const [selectedSystemSetting, setSelectedSystemSetting] = useState("Time"); // State to track selected item


  // Handler for clicking a list item
  const handleAiItemClick = (AiType) => {
    setSelectedAi(AiType); // Update state with clicked item
  };

  const handleNetworkItemClick = (networkType) => {
    setSelectedNetwork(networkType); // Update state with clicked item
  };



  const handleSystemItemClick = (systemType) => {
    setSelectedSystemSetting(systemType); // Update state with clicked item
  };
  // Refs for save functions from child components
  const saveGeneralRef = useRef(null);
  const saveMediaRef = useRef(null);
  const saveAiRef = useRef(null);
  const saveNetworkRef = useRef(null);
  const saveSystemSettingsRef = useRef(null);

  const handleSave = () => {
    if (activeTab === "General" && saveGeneralRef.current) {
      saveGeneralRef.current();
    } else if (activeTab === "Media" && saveMediaRef.current) {
      saveMediaRef.current();
    } else if (activeTab === "AI Setting" && saveAiRef.current) {
      saveAiRef.current();
    } else if (activeTab === "Network" && saveNetworkRef.current) {
      saveNetworkRef.current();
    } else if (activeTab === "System" && saveSystemSettingsRef.current) {
      saveSystemSettingsRef.current();
    }
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case "General":
        return (
          <General
            device={device}
            onSave={(saveFunc) => (saveGeneralRef.current = saveFunc)}
          />
        );
      case "Media":
        return (
          <Media
            selectedMedia={selectedMedia}
            deviceId={device.deviceid}
            onSave={(saveFunc) => (saveMediaRef.current = saveFunc)}
          />
        );
      case "AI Setting":
        return (
          <EdgeAi
            selectedAi={selectedAi}
            device={device.deviceid}
            onSave={(saveFunc) => (saveAiRef.current = saveFunc)}
          />
        );
      case "Network":
        return (
          <Network
            selectedMedia={selectedNetwork}
            deviceId={device.deviceid}
            onSave={(saveFunc) => (saveNetworkRef.current = saveFunc)}
          />
        );
      case "System":
        return (
          <SystemSetting
          selectedSystemSetting={selectedSystemSetting}
            deviceId={device.deviceid}
            onSave={(saveFunc) => (saveSystemSettingsRef.current = saveFunc)}
          />
        );
      default:
        return <div>Select a tab</div>;
    }
  };

  const renderCameraInfo = () => {
    switch (activeTab) {
      case "General":
        return (
          <>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a31f072c5a476878b51af8d11d2a0789852947bb4cf5e6068593eb7fceb52043?apiKey=21c2172730ed406bb6f91788633e80d1&"
              className={styles.image}
              alt=""
            />
            <br />
            <p className={styles.cameraInfo}>
              <strong>Camera Model:</strong> S-Series <br />
              <strong>SNumber:</strong> {device.deviceid} <br />
              <strong>Signal:</strong> Strong <br />
              <strong>Firmware:</strong> v12.97630 <br />
              <span style={{ color: "green" }}>{device.status}</span>
            </p>
          </>
        );
      case "Media":
        return (
          <>
            <ul className={styles.mediaList}>
              <li onClick={() => handleItemClick("Video Setting")}>
                Video Setting
              </li>
              <li onClick={() => handleItemClick("Image Setting")}>
                Image Setting
              </li>
              <li onClick={() => handleItemClick("ISP")}>ISP</li>
              <li onClick={() => handleItemClick("Privacy Mask")}>
                Privacy Mask
              </li>
              <li onClick={() => handleItemClick("OSD")}>OSD</li>
            </ul>
            <span>Connected</span>
          </>
        );
      case "AI Setting":
        return (
          <>
            <ul className={styles.mediaList}>
              <li onClick={() => handleAiItemClick("Motion Detection")}>
                Motion Detection
              </li>
              <li onClick={() => handleAiItemClick("Human Detection")}>
                Human Detection
              </li>
              <li onClick={() => handleAiItemClick("Face Detection")}>
                Face Detection
              </li>
              <li onClick={() => handleAiItemClick("Line Crossing Detection")}>
                Line Crossing Detection
              </li>
              <li onClick={() => handleAiItemClick("Area Detection")}>
                Area Detection
              </li>
              <li onClick={() => handleAiItemClick("Traffic Statistics")}>
                Traffic Statistics
              </li>
              <li
                onClick={() =>
                  handleAiItemClick("Unattended Baggage Detection")
                }
              >
                Unattended Baggage Detection
              </li>
              <li onClick={() => handleAiItemClick("Missing Object Detection")}>
                Missing Object Detection
              </li>
            </ul>
            <span>Connected</span>
          </>
        );
      case "Network":
        return (
          <>
            <p className={styles.networkInfo}>
              <strong>Network Type:</strong> Wi-Fi <br />
              <strong>Signal Strength:</strong> Excellent{" "}
              <IoWifi style={{ display: "inline" }} /> <br />
              <strong>IP Address:</strong> 192.168.1.100 <br />
              <span style={{ color: "green" }}>Network connected</span>
            </p>
            <ul className={styles.mediaList}>
              <li onClick={() => handleNetworkItemClick("Local Setup")}>
                Local Setup
              </li>
              {/* <li onClick={() => handleNetworkItemClick("RTMP Setup")}>
                RTMP Setup
              </li>
              <li onClick={() => handleNetworkItemClick("FTP Setting")}>
                FTP Setting
              </li> */}
              <li onClick={() => handleNetworkItemClick("4G Network Settings")}>
                4G Network Settings
              </li>
            </ul>

            <span>Connected</span>
          </>
        );
      case "System":
        return (
          <>
            <p className={styles.systemInfo}>
              <strong>System Uptime:</strong> 12 days <br />
              <strong>Firmware Version:</strong> v12.97630 <br />
              <strong>Last Update:</strong> 5 days ago <br />
              <span style={{ color: "green" }}>System healthy</span>
            </p>

            <ul className={styles.mediaList}>
              <li onClick={() => handleSystemItemClick("Time")}>Time</li>
              <li onClick={() => handleSystemItemClick("Device Info")}>
                Device Info
              </li>
            </ul>

            <span>Connected</span>
          </>
        );
      default:
        return (
          <p className={styles.cameraInfo}>
            <strong>Camera Model:</strong> YZ 22097 <br />
            <strong>Serial Number:</strong> YKAA-027648-B6DKJ{" "}
            <MdContentCopy style={{ display: "inline" }} /> <br />
            <strong>Signal:</strong> Strong{" "}
            <IoWifi style={{ display: "inline" }} /> <br />
            <strong>Firmware:</strong> v12.97630 <br />
            <span style={{ color: "green" }}>Connected</span>
          </p>
        );
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.heading}>
          <div className={styles.textContainer}>
            <h1 style={{ color: "black", fontWeight: "bold" }}>
              Godown No 11 Gate &nbsp;
              <b style={{ fontWeight: "lighter", fontSize: "12px" }}>
                {" "}
                11:55 55 PM{" "}
              </b>
            </h1>
          </div>
          <div className={styles.modalicon}>
            <IoSettingsOutline className={styles.setting} />
            <LuBadgeHelp />
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.part1}>{renderCameraInfo()}</div>
          <div className={styles.verticalline}></div>
          <div className={styles.part2}>
            <div className={styles.tabGroup}>
              {["General", "Media", "AI Setting", "Network", "System"].map(
                (tab) => (
                  <button
                    key={tab}
                    className={`${styles.tab} ${
                      activeTab === tab ? styles.active : ""
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                )
              )}
            </div>
            <div className={styles.tabContent}>{renderTabContent()}</div>
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <button className={styles.submitButton} onClick={onClose}>
            Close
          </button>
          <button className={styles.cancelButton} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default Settings;
