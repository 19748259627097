import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import CustomerCanvas from "../../CustomerCanvas";
import styles from "../Settings.module.css";
import { Button, Checkbox } from "@chakra-ui/react";

function CustomerTrafficStats({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [nLastHour, setnLastHour] = useState(0);
  const [spOSD, setspOSD] = useState(false);
  const [direction, setDirection] = useState("A->B");
  const [error, setError] = useState(null);
  const [detectLine, setDetectLine] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getcts`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsEnabled(response.data.Enabled);
        setDirection(response.data.Direction);
        setDetectLine(response.data.DetectLine);
        setspOSD(response.data.spOSD);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/setcts`,
        {
          Enabled: isEnabled,
          DetectLine: detectLine,
          DetectObj: "Human",
          Direction: direction,
          nLastHour: nLastHour,
          spOSD: spOSD,
        },
        {
          params: { deviceId: deviceId },
        }
      );

      console.log("Settings saved", response.data);
    } catch (error) {
      console.error("Error saving settings", error);
    }
  };

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };

  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (line, direction) => {
    setDetectLine(line);
    setDirection(direction);
  };

  return (
    <div className={styles.notify}>
      

      <div className={styles.checkboxContainer}  style={{ marginBottom: "16px",color:'#7F56D9' }}>
        <label style={{ fontSize: "18px", fontWeight: "bold" }}>Customer Traffic Statistics</label>
      </div>


      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="enabled" style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enabled
        </label>
        <Checkbox
          isChecked={isEnabled}
          id="enabled"
          onChange={(e) => setIsEnabled(e.target.checked)}
          style={{
            borderColor:'black',
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="whiteLight" style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Real-Time Display of Customer Traffic
        </label>
        <Checkbox
          isChecked={spOSD}
          id="whiteLight"
          onChange={(e) => setspOSD(e.target.checked)}
          style={{
            borderColor:'black',
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div >
        <Button onClick={openCanvasModal} style={{
        backgroundColor: "#88ffcc",
        color: "black",
        padding: "8px 12px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }}>Open Canvas</Button>
      </div>

      <div>
        <Button onClick={handleSave} style={{
        backgroundColor: "#7F56D9" ,
        color: "white",
        padding: "8px 12px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }}>
          Save
        </Button>
      </div>
      <CustomerCanvas
        isOpen={isCanvasModalOpen}
        onClose={closeCanvasModal}
        onCanvasData={handleCanvasData}
        existingCoordinates={detectLine}
        existingDirection={direction}
        deviceId={deviceId}
      />
    </div>
  );
}

export default CustomerTrafficStats;
