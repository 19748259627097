import React, { useEffect, useState } from 'react';
import './Timeline.css';
import styles from './Dashboard.module.css';
import axios from 'axios';

function Cloud_Timeline({date,deviceid,plan,onUrlChange}) {
  
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [totaldata, setTotalData] = useState();
  const [TypeFlags2, setTypeFlags2] = useState('0x1');
  const [currentPage, setCurrentPage] = useState(1);
  
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const [hoveredChunk, setHoveredChunk] = useState({ start: null, end: null, x: 0, y: 0, time: null });
  const [currentPlaybackTimePosition, setCurrentPlaybackTimePosition] = useState(null);
  const userEmail = localStorage.getItem('email');


  const handleUpdateUrl = (epochTime) => {
    // const offsetInSeconds = 19800;
    const offsetInSeconds = userEmail === "poojan@adiance.com" ? 14400 : 19800; 
    // Adjust the epochTime by removing the offset
    const adjustedEpochTime = epochTime - offsetInSeconds;
    // console.log("adjustedEpochTime",adjustedEpochTime)
    const newUrl = `https://media.arcisai.io:8443/record/${plan}/RTSP-${deviceid}/${adjustedEpochTime}.mp4`;
    console.log(newUrl)
    onUrlChange(newUrl);
  };

  useEffect(() => {
    const fetchFiles = async () => {
     
      try {
        const streampath = `${plan}/RTSP-${deviceid}`; // Define the streampath
        // console.log(date)
        const response = await axios.get('https://media.arcisai.io:5080/api/fmp4-files', {
          params: { streampath ,date},
          
        });
console.log(response)
        setTotalData(response.data.totalSize)
        const transformedData = response.data.files.map(fileData => {
          const TimeStartUTC = parseInt(fileData.file.split('.')[0], 10);
          const TimeEndUTC = TimeStartUTC + fileData.duration;
          
          // Adding 5 hours and 30 minutes to convert UTC to IST
          // const offsetInSeconds = 19800;
          const offsetInSeconds = userEmail === "poojan@adiance.com" ? 14400 : 19800; 
          const TimeStartIST = TimeStartUTC + offsetInSeconds;
          const TimeEndIST = TimeEndUTC + offsetInSeconds;
          
          return {
            Channel: 0, // Assuming single channel, you can modify this if needed
            TimeStart: TimeStartIST,
            TimeEnd: TimeEndIST,
            Type: 'TIME'
          };
          });

      // console.log(transformedData)
        setData(transformedData);
        const currentPosition = getCurrentTimePosition();
        setCurrentPlaybackTimePosition(currentPosition);
      } catch (error) {
        console.error('Error fetching Recording files:', error);
        setError(error.message);
      }
    };

    fetchFiles();
  }, [date, deviceid]);

  // Generate hourly markers for the full day in GMT
  const generateHourlyMarkers = () => {
    const hourlyMarkers = [];
    const startOfDay = new Date(`${date}T00:00:00Z`); // GMT (UTC) time

    for (let hour = 0; hour < 24; hour++) {
      hourlyMarkers.push(new Date(startOfDay.getTime() + hour * 60 * 60 * 1000));
    }
    return hourlyMarkers;
  };

  // Calculate the position of a time within the 24-hour timeline in GMT
  const calculatePosition = (time) => {
    const startOfDay = new Date(`${date}T00:00:00Z`); // GMT (UTC) time
    const totalDuration = 24 * 60 * 60 * 1000;
    const elapsed = time - startOfDay.getTime();
    return (elapsed / totalDuration) * 100;
  };

  const getCurrentTimePosition = () => {
    const currentTime = new Date(); // Current UTC time
    const currentIstTime = userEmail === "poojan@adiance.com" ? new Date(currentTime.getTime() + (4 * 60 * 60 * 1000)) : new Date(currentTime.getTime() + (5.5 * 60 * 60 * 1000)); // Convert to IST
    return calculatePosition(currentIstTime);
  };

  const fileMarkers = data.map((file, index) => {
    const startTime = new Date(file.TimeStart * 1000);
    const endTime = new Date(file.TimeEnd * 1000);
    const startPercentage = calculatePosition(startTime);
    const endPercentage = calculatePosition(endTime);
    const width = endPercentage - startPercentage;
    return { startTime, endTime, startPercentage, width, index, TimeStart: file.TimeStart };
  });

  const handleFileClick = (index) => {
    setHighlightedIndex(index);
  };

  const handleMouseMove = (event) => {
    const timelineRect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - timelineRect.left;
    const clickPercent = (x / timelineRect.width) * 100;

    const startOfDay = new Date(`${date}T00:00:00Z`); // GMT (UTC) time
    const totalDuration = 24 * 60 * 60 * 1000;
    const hoverTime = new Date(startOfDay.getTime() + (clickPercent / 100) * totalDuration);
    setHoveredChunk({
      x: x,
      y: event.clientY - timelineRect.top,
      time: Math.floor(hoverTime.getTime() / 1000) // Display epoch time
    });
  };

  const handleMouseLeave = () => {
    setHoveredChunk({ x: 0, y: 0, time: null });
  };

  const handleTimelineClick = (event) => {
    const timelineRect = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - timelineRect.left;
    const clickPercent = (clickX / timelineRect.width) * 100;

    const startOfDay = new Date(`${date}T00:00:00Z`); // GMT (UTC) time
    const totalDuration = 24 * 60 * 60 * 1000;
    const newPlaybackTime = new Date(startOfDay.getTime() + (clickPercent / 100) * totalDuration);

    const epochTime = Math.floor(newPlaybackTime.getTime() / 1000);
    const currentUtcTime = new Date(); // Current UTC time
    const currentIstTime =  userEmail === "poojan@adiance.com" ? new Date(currentUtcTime.getTime() + (4 * 60 * 60 * 1000)) : new Date(currentUtcTime.getTime() + (5.5 * 60 * 60 * 1000)); // Convert to IST
      
    const clickedEpochTime = Math.floor(newPlaybackTime.getTime() / 1000);
    let closestFileMarker = null;
    let minDiff = Infinity;

    fileMarkers.forEach(file => {
    const diffStart = Math.abs(file.TimeStart - clickedEpochTime);
    if (diffStart < minDiff) {
      minDiff = diffStart;
      closestFileMarker = file;
    }
  });

    if (newPlaybackTime > currentIstTime) {
        // If the clicked time is in the future, set the URL to live stream
        // selectedDevice.planname === "LIVE" ? 
        //   `https://${selectedDevice.deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`
        //  : `https://media.arcisai.io:8443/hdl/${selectedDevice.planname}/RTSP-${selectedDevice.deviceid}.flv`
        const liveUrl = plan === "LIVE" ? `https://${deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==` :
        `https://media.arcisai.io:8443/hdl/${plan}/RTSP-${deviceid}.flv`;
        
        onUrlChange(liveUrl);
        const currentPosition = getCurrentTimePosition();
        setCurrentPlaybackTimePosition(currentPosition);
      } else {
        // Otherwise, set the URL for the specific playback time
        setCurrentPlaybackTimePosition(calculatePosition(newPlaybackTime));
        handleUpdateUrl(closestFileMarker.TimeStart);
      }
      
  };
  

  return (
    <>
    
    <div className="timeline-container">
      [Data-Usage : {totaldata}]
      <div
        className="timeline"
        onClick={handleTimelineClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {generateHourlyMarkers().map((hourMarker, index) => (
          <div
            key={`hour-${index}`}
            className="hour-marker"
            style={{ left: `${calculatePosition(hourMarker)}%` }}
          >
            <div className="hour-marker-indicator"></div>
            <div className="hour-marker-label">{hourMarker.getUTCHours()}:00</div>
          </div>
        ))}

        {fileMarkers.map(({ startTime, endTime, startPercentage, width, index }) => (
          <div
            key={`file-${index}`}
            className={`cloudfile-marker ${highlightedIndex === index ? 'highlighted' : ''}`}
            style={{ left: `${startPercentage}%`, width: `${width}%` }}
            onClick={() => handleFileClick(index)}
          >
            <div className="file-marker-indicator"></div>
          </div>
        ))}

      {hoveredChunk.time !== null && (
          <div
            className="hover-tooltip"
            style={{ left: `${hoveredChunk.x}px`, top: `${hoveredChunk.y - 40}px` }}
          >
            {new Date(hoveredChunk.time * 1000).toISOString().substring(11, 19)} {/* Display time in HH:MM:SS */}
          </div>
        )}

        {hoveredChunk.time !== null && (
          <div
            className="hover-highlight"
            style={{ left: `${hoveredChunk.x}px`, top: `0px`, height: '100%', width: '2px' }}
          />
        )}
        {currentPlaybackTimePosition !== null && (
                    <div
                      className="current-time-marker"
                      style={{
                        left: `${currentPlaybackTimePosition}%`,
                        height: '100%',
                        backgroundColor: 'red',
                        position: 'absolute',
                        width: '2px',
                      }}
                    />
                  )}
        <div className="playback-marker" />
      </div>
   
    </div>

    </>
  );
}

export default Cloud_Timeline;






 