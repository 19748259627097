import React, { useEffect, useState } from 'react';
import styles from './AIEvents.module.css';

import Calendar from '../../components/AIEvents/Calender';
import EventFilter from '../../components/AIEvents/EventFilter';
import EventCard from '../../components/AIEvents/EventCard';
import { useNavigate } from 'react-router-dom';
import { getSDCardData } from '../../services/sdcard';
import { getEventList } from '../../services/aiCamera';
import Modal from '../../components/ModalView/Modal';

const AIEvents = () => {
  const [data, setData] = useState(null);
  const [cloudData, setCloudData] = useState(null);
  const [error, setError] = useState(null);
  const pageSize = 12; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [selectedDate, setSelectedDate] = useState(() => getCurrentISTDate());

  const handleDateSelect = (selectedDate) => {
    setSelectedDate(selectedDate);
  };

  const navigate = useNavigate();
  const arcisclick = () => {
    navigate('/arcisgpt');
  };

  const notifyclick = () => {
    navigate('/notification');
  };

  const reportclick = () => {
    navigate('/reports');
  };

  const [selectedDevice, setSelectedDevice] = useState(null);
  const [TypeFlags2, setTypeFlags2] = useState('0x2');

  const handleDeviceSelect = (device) => {
    setSelectedDevice(device);
  };

  const handleAiSelect = (selectedai) => {
    setTypeFlags2(selectedai);
  };

useEffect(() => {
  const fetchData = async () => {
    try {
      setData(null); // Clear previous data
      setCloudData(null); // Reset cloudData as well

      let edgeData = null; // Default to null if edge data is not available
      let cloudData = null;

      // Attempt to fetch edge data
      try {
        edgeData = await getSDCardData(currentPage, selectedDevice?.deviceid, selectedDate, TypeFlags2, pageSize);
      } catch (error) {
        console.error('Error fetching edge data:', error); // Log edge data error but continue
        edgeData = null; // Ensure edgeData is null in case of error
      }

      // Fetch cloud data
      try {
        cloudData = await getEventList(currentPage, selectedDate, pageSize);
        console.log('clouddata', cloudData);
      } catch (error) {
        console.error('Error fetching cloud data:', error); // Handle any error in cloud data fetching
        cloudData = null; // Ensure cloudData is null in case of error
      }

      // Map edge data if it exists
      const updatedEdgeData = edgeData?.Item?.map((item) => ({
        ...item,
        source: 'Edge',
      })) || []; // Fallback to an empty array if no edge data

      // Map cloud data if it exists
      const updatedCloudData = cloudData?.map((item) => ({
        ...item,
        source: 'Cloud',
      })) || []; // Fallback to an empty array if no cloud data

      // Merge both Edge and Cloud data into one array
      const mergedData = [...updatedEdgeData, ...updatedCloudData];

      setData(mergedData); // Set merged data in state
      console.log('Merged Data ::', mergedData);
    } catch (error) {
      setError(error.message);
    }
  };

  fetchData();
}, [currentPage, selectedDevice, selectedDate, TypeFlags2]);


  

  const [selectedUrl, setSelectedUrl] = useState(null);
  const handleGoBack = () => {
    setSelectedUrl(null);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleUrlSelection = (url) => {
    setSelectedUrl(url);
    setIsModalOpen(true);
    console.log('Selected URL:', url);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <main className={styles.eventsDesktop}>
      {selectedUrl && isModalOpen && (
        <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
          <Modal url={selectedUrl} device={selectedDevice} status={selectedDevice?.status} onClose={handleCloseModal} />
        </div>
      )}
      <div className={styles.contentWrapper}>
        <section className={styles.mainContent}>
          <h1 className={styles.pageTitle}>AI Events</h1>
          <p className={styles.pageDescription}>List of events triggered by cameras</p>
          <div className={styles.tabGroup}>
            <button className={`${styles.tab} ${styles.active}`}>Events</button>
            <button className={styles.tab} onClick={notifyclick}>Notifications</button>
            <button className={styles.tab} onClick={reportclick}>Reports</button>
            <button className={styles.tab} onClick={arcisclick}>ArcisGPT</button>
          </div>

          <Calendar onDateSelect={handleDateSelect} onDeviceSelect={handleDeviceSelect} />
          <EventFilter onDeviceSelect={handleDeviceSelect} onAiSelect={handleAiSelect} onPageChange={handlePageChange} />

          <div className={styles.eventCardGrid}>
            {data && data.length > 0 ? (
              data.map((item, index) => {
                const duration = item.TimeEnd - item.TimeStart;
                const length = `${Math.floor(duration / 60)} min ${duration % 60} sec`;

                return (
                  <EventCard
                    key={`${item.source}-${index}`} // Unique key for each event based on source and index
                    title={item.Type || item.modelname}
                    TimeStart={item.TimeStart || item.sendtime}
                    TimeEnd={item.TimeEnd}
                    deviceId={selectedDevice?.deviceid || item.cameraid?.replace(/^RTSP-/, '')}
                    name={selectedDevice?.cameraname || 'Unknown'}
                    length={length}
                    source={item.source}
                    imageUrl={item.imgurl || 'https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'} // Fallback image URL
                    onUrlSelect={handleUrlSelection}
                  />
                );
              })
            ) : (
              <div>No events found.</div>
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

const formatDateToISO = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Utility function to get current IST date
const getCurrentISTDate = () => {
  const offset = 5.5; // IST is UTC+5:30
  const localDate = new Date();
  const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
  const istDate = new Date(utcDate.getTime() + offset * 3600000);
  return formatDateToISO(istDate);
};

export default AIEvents;

// import React, { useEffect, useState } from 'react';
// import styles from './AIEvents.module.css';

// import Calendar from '../../components/AIEvents/Calender';
// import EventFilter from '../../components/AIEvents/EventFilter';
// import EventCard from '../../components/AIEvents/EventCard';
// import { useNavigate } from 'react-router-dom';
// import { getSDCardData } from '../../services/sdcard';
// import {getEventList} from '../../services/aiCamera'
// import { Text } from '@chakra-ui/react';
// import Modal from '../../components/ModalView/Modal';


// const AIEvents = () => {
//   const [data, setData] = useState(null)
//   const [cloudData, setCloudData] = useState(null)
//   const [error, setError] = useState(null);
//   const pageSize = 12; // Number of items per page
//   const [currentPage, setCurrentPage] = useState(1);  
//   const handlePageChange = (page) => {
//     setCurrentPage(page);
    
//   }

//   const [selectedDate, setSelectedDate] = useState(() => getCurrentISTDate());

//   const handleDateSelect = (selectedDate) => {
    
//     setSelectedDate(selectedDate)
//     // Handle the selected date here (e.g., fetch data for the selected date)
//   };

//   const navigate = useNavigate()
//   const arcisclick = () => {
//     navigate('/arcisgpt');
//   };

//   const notifyclick = () => {
//     navigate('/notification');
//   };

//   const reportclick = () => {
//     navigate('/reports');
//   };
//   const [selectedDevice, setSelectedDevice] = useState(null);
//   const [TypeFlags2, setTypeFlags2] = useState('0x2');

//   const handleDeviceSelect = (device) => {
//     setSelectedDevice(device);
//   };

//   const handleAiSelect = (selectedai) => {
//     setTypeFlags2(selectedai);
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setData(null);
//         setCloudData(null); // Reset cloudData as well
  
//         const edgeData = await getSDCardData(currentPage, selectedDevice.deviceid, selectedDate, TypeFlags2, pageSize);
//         const cloudData = await getEventList(currentPage, selectedDate, pageSize);
//         console.log('clouddata', cloudData);
  
//         const updatedEdgeData = {
//           ...edgeData,
//           Item: edgeData.Item.map(item => ({
//             ...item,
//             source: "Edge"
//           }))
//         };
  
//         const updatedCloudData = {
//           ...cloudData,
//           Item: cloudData.map(item => ({
//             ...item,
//             source: "Cloud" // Marking the source as "Cloud"
//           }))
//         };
  
//         setData(updatedEdgeData);
//         setCloudData(updatedCloudData); // Set cloudData in state
//         console.log("Updated Edge Data ::", updatedEdgeData);
//         console.log("Updated Cloud Data ::", updatedCloudData);
  
//       } catch (error) {
//         setError(error.message);
//       }
//     };
  
//     fetchData();
//   }, [currentPage, selectedDevice, selectedDate, TypeFlags2]);

//   const [selectedUrl, setSelectedUrl] = useState(null);
//   const handleGoBack = () => {
//     setSelectedUrl(null); // Set selectedDevice to null to go back to the device list
//   };

//   const [isModalOpen, setIsModalOpen] = useState(false); 

//   const handleUrlSelection = (url) => {
//     setSelectedUrl(url);
//     setIsModalOpen(true)
//     // You can also perform other actions with the URL here
//     console.log('Selected URL:', url);
//   };

//   const handleCloseModal = () => setIsModalOpen(false);
//   return (
//     <main className={styles.eventsDesktop}>
//     {selectedUrl && isModalOpen && (
//         <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
//           <Modal url={selectedUrl} device={selectedDevice} status={selectedDevice.status} onClose={handleCloseModal} />
//         </div>
//       )}
//       {/* // <div style={{ marginLeft: '270px' }}>
       
//       //   <DeviceComponent 
//       //   url={selectedUrl} 
//       //   device={selectedDevice} goBack={handleGoBack} status={selectedDevice.status} />
//       //   </div>
//       // ) : ( */}
//       <div className={styles.contentWrapper}>
        
//         <section className={styles.mainContent}>
//           <h1 className={styles.pageTitle}>AI Events</h1>
//           <p className={styles.pageDescription}>
//             List of events triggered by cameras
//           </p>
//           <div className={styles.tabGroup}>
//         <button className={`${styles.tab} ${styles.active}`}>Events</button>
//         <button className={styles.tab} onClick={notifyclick}>Notifications</button>
//         <button className={styles.tab} onClick={reportclick}>Reports</button>
//         <button className={styles.tab}onClick={arcisclick}>ArcisGPT</button>
//       </div>
      
//           <Calendar onDateSelect={handleDateSelect} onDeviceSelect={handleDeviceSelect} />
//           <EventFilter onDeviceSelect={handleDeviceSelect} onAiSelect={handleAiSelect} onPageChange={handlePageChange}/>

//           <div className={styles.eventCardGrid}>
//             {data && data.Item && data.Item.length > 0 ? (
//               <>
//                 {data.Item.map((item, index) => {
//                   const duration = item.TimeEnd - item.TimeStart;
//                   const length = `${Math.floor(duration / 60)} min ${duration % 60} sec`;

//                   return (
//                     <EventCard
//                       key={`edge-${index}`} // Unique key for Edge events
//                       title={item.Type}
//                       TimeStart={item.TimeStart}
//                       TimeEnd={item.TimeEnd}
//                       deviceId={selectedDevice.deviceid}
//                       name={selectedDevice.cameraname}
//                       length={length}
//                       source={item.source}
//                       imageUrl={`https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1`}
//                       onUrlSelect={handleUrlSelection}
//                     />
//                   );
//                 })}

//                 {cloudData && cloudData.Item && cloudData.Item.length > 0 && (
//                   <div className={styles.cloudSection}>
//                     {cloudData.Item.map((item, index) => {
//                       const duration = item.TimeEnd - item.TimeStart;

//                       return (
//                         <EventCard
//                           key={`cloud-${index}`} // Unique key for Cloud events
//                           title={item.Type}
//                           TimeStart={item.sendtime}
//                           TimeEnd={item.TimeEnd}
//                           deviceId={item.cameraid.replace(/^RTSP-/, '')}
//                           name={selectedDevice.cameraname}
//                           // length={length}
//                           source={item.source}
//                           imageUrl={item.imgurl}
//                           onUrlSelect={handleUrlSelection}
//                         />
//                       );
//                     })}
//                   </div>
//                 )}
//               </>
//             ) : (
//               <div>No events found.</div> // Message for no data
//             )}
//           </div>

//         </section>
//       </div>
//       {/* // )} */}
//     </main>
//   );
// };



// const formatDateToISO = (date) => {
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
//   const day = String(date.getDate()).padStart(2, '0');
//   return `${year}-${month}-${day}`;
// };

// // Utility function to get current IST date
// const getCurrentISTDate = () => {
//   const offset = 5.5; // IST is UTC+5:30
//   const localDate = new Date();
//   const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60000));
//   const istDate = new Date(utcDate.getTime() + (offset * 3600000));
//   return formatDateToISO(istDate);
// };

// export default AIEvents;