import React, { useState, useEffect } from 'react';
import Calendar from './Calender';
import EventList from './EventList';
import styles from './Notification.module.css';
import { useNavigate } from 'react-router-dom';
import { getEventList } from '../../services/aiCamera';

const Notification = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dataAvailable, setDataAvailable] = useState(true); // New state for data availability

  const navigate = useNavigate();

  const arcisclick = () => {
    navigate('/arcisgpt');
  };

  const notificationClick = () => {
    navigate('/notification');
  };

  const eventclick = () => {
    navigate('/AIEvents');
  };

  const reportclick = () => {
    navigate('/reports');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const customerid = localStorage.getItem('userId');
        const page = 1;
        const itemsPerPage = 10;

        // Use selectedDate if available, otherwise default to today's date
        const response = await getEventList(customerid, page, itemsPerPage, selectedDate);
        console.log(response);
        
        // Check if data is available for the selected date
        setDataAvailable(response.length > 0);
        setData(response);
      } catch (err) {
        setError(err.message || 'Something went wrong');
      }
    };

    fetchData();
  }, [selectedDate]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    console.log("Selected Date:", date); // Do something with the selected date
  };

  return (
    <main className={styles.eventsDesktop}>
      <div className={styles.contentWrapper}>
        <section className={styles.mainContent}>
          <h1 className={styles.pageTitle}>Notification</h1>
          <p className={styles.pageDescription}>
            List of events Notification
          </p>
          <div className={styles.tabGroup}>
            <button className={`${styles.tab}`} onClick={eventclick}>Events</button>
            <button className={`${styles.tab} ${styles.active}`} onClick={notificationClick}>Notifications</button>
            <button className={styles.tab} onClick={reportclick}>Reports</button>
            <button className={styles.tab} onClick={arcisclick}>
              ArcisGPT
            </button>
          </div>

          <Calendar selectedDate={selectedDate} onDateSelect={handleDateSelect} />

          {dataAvailable ? (
            <EventList data={data} />
          ) : (
            <div className={styles.noDataMessage}>Data is Unavailable</div>
          )}

        </section>
      </div>
    </main>
  );
};

export default Notification;
