import React, { useEffect, useState } from 'react';
import styles from './Dashboard.module.css';

function DeviceCard({ name, serial, status, lastSnapshot, image, onClick }) {

  const [showIcons, setShowIcons] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const toggleIcons = () => {
    if (!isMobile)
      setShowIcons(prev => !prev);
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleCopySerial = () => {
    navigator.clipboard.writeText(serial)
      .then(() => {
        console.log('Serial copied to clipboard:', serial);
        // Optionally, provide user feedback here
        // alert('Serial copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy serial:', err);
      });
  };

  return (
    <article className={styles.deviceCard} >
      <img onClick={onClick} src={image} alt={`${name} snapshot`} className={styles.deviceImage} />
      <div className={styles.deviceInfo}>
        <h3 className={styles.deviceName}>{name}</h3>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/0402d72c28a2a4f0955de54a911d20e8e980fca363eb868427e7d1b8abe86052?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
          alt="" className={styles.deviceIcon}
          onClick={toggleIcons} />
      </div>
      {/* <div className={styles.deviceSerial}>
        <span>{serial}</span>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/96bff4fd3c64131e284f884988c8583109aa215f618cff8b391d9905beea4f4e?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" 
        alt="" className={styles.serialIcon} onClick={handleCopySerial} style={{ cursor: 'pointer' }} />
      </div> */}
      {/* <p className={styles.deviceSnapshot}>Snapshot: {lastSnapshot}</p> */}
      <img onClick={onClick} src="https://cdn.builder.io/api/v1/image/assets/TEMP/6edb3eab5d03ae495340f14fc6256d578de6449742ce6b2f163d090e5c19cb4e?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.deviceStatusIcon} />

      {/* device icons */}
      <div className={`${styles.deviceIcons} ${showIcons ? styles.show : ''} `}>
        <div className={styles.deviceIconWrapper}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/590ec1db7db6dbe8d37e8c6a56b3c82384413597d4472a234a3718ec3b5fe247?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt='Icon1'
            className={styles.deviceIcon}
          />
          <div>Recharge</div>
        </div>
        <div className={styles.deviceIconWrapper}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/df0b5543c4858742c792e2f4f66764b8360521a393eb43ee1f986ccb9702f14d?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
            alt='Event Icon'
            className={styles.deviceIcon}
          />
          <div>Events</div>
        </div>
        <div className={styles.deviceIconWrapper}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fab1621896bb2881f80a087ef1fab35c079c91bd85b959315f1cd5fb0516cc9d?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
            alt='Share Icon'
            className={styles.deviceIcon}
          />
          <div>Share</div>
        </div>
        <div className={styles.deviceIconWrapper}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/11aca4e80b1d5149a8114b8be2cec25606cd3ca8fade74daf5bb31d2e7f82c40?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
            alt='Playback Icon'
            className={styles.deviceIcon}
          />
          <div>Playback</div>
        </div>
      </div>
      <div className={`${styles.deviceStatus} ${styles[status]}`}>{status}</div>

    </article>
  );
}

export default DeviceCard;