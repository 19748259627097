import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';

function PrivacyMask_Setup({ deviceId, onSave }) {
  const handlePrivacyMask = async () => {
    console.log("privacy Mask");
  };
  return (
    <div className={styles.notify}>
      {/* IR Mode selection */}
      <div className={styles.checkboxContainer}  style={{ marginBottom: "16px",color:'#7F56D9' }}>
        <label style={{ fontSize: "18px", fontWeight: "bold" }}> Privacy Mask Setting</label>
      </div>



      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
      <label
          htmlFor="privacy_mask"
          style={{  fontWeight: "bold",marginRight:'16px' }}
        >
          Enable Privacy Mask
        </label>
        
        <input
          type="checkbox"
          //   checked={isDigitalChecked}
          //   onChange={handleDigitalCheckboxChange}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
    
      </div>
      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <select
          placeholder="Color"
          // className={styles.selectInput}
          // value={irMode}
          // onChange={handleIrModeChange}
          style={{
            padding: "8px 18px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "14px",
            color: "#333",
            backgroundColor: "#fff",
          }}
        >
             <option value="" disabled selected>
            Color
          </option>
          <option value="5aaf5a">green</option>
          <option value="000000">white</option>
          <option value="006ecf">blue</option>
        </select>
      </div>
      <div>
        <button
          colorScheme="blue"
          onClick={handlePrivacyMask}
          style={{
            backgroundColor: "#7F56D9" ,
            color: "white",
            padding: "4px 8px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
                      fontWeight: "bold",
            marginLeft:'10px'
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}

export default PrivacyMask_Setup;
