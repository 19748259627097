import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';
import { useToast } from '@chakra-ui/react';


function Video_Setup({ deviceId, onSave }) {
  const [data, setData] = useState({
    microphoneType: '',
    profile: '',
    encodeType: '',
    stream: '',
    resolution: '',
    bitRateControlType: '',
    channelName: '',
    constantBitRate: '',
    frameRate: ''
  });
  const [error, setError] = useState(null);
  const [quality, setQuality] = useState(''); // Default empty or a preset value
  const [loading, setLoading] = useState(false); // Loading state for asynchronous submission
  const toast = useToast(); // For displaying notifications
  const handleQualityChange = async (e) => {
    const selectedQuality = e.target.value;
    setQuality(selectedQuality);

    // Automatically trigger the submission process with the selected quality
    await handleSubmit(selectedQuality);
  };
  // Fetch the current video settings when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/p2p/get-videoSettings`, {
          params: { deviceid: deviceId },
        });
        console.log(response.data);
        setData(response.data);
        const qualityResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/p2p/getQuality`,
          {
            deviceid: deviceId,
          }
        );

        // Assuming the quality is inside the response data
        if (qualityResponse.data && qualityResponse.data.quality) {
          setQuality(qualityResponse.data.quality); // Set quality from the response
        } else {
          setQuality('high'); // Default to 'high' if no quality found
        }
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to save the settings
  const handleMediaContent = () => {
    const updatedData = {
      deviceId: deviceId,
      codecType: data.profile,
      h264Profile: 'high', // This value is static, adjust as necessary
      resolution: data.resolution,
      freeResolution: false,
      bitRateControlType: data.bitRateControlType,
      constantBitRate: parseInt(data.constantBitRate, 10), // Ensure BPS is a number
      frameRate: parseInt(data.frameRate, 10), // Ensure FPS is a number
      channelName:data.channelName
    };

    const config = {
      headers: {
        Authorization: 'Basic ' + btoa('admin:'), // Assuming 'admin' is the username with no password
        'Content-Type': 'application/json',
      },
    };

    axios
      .put(`${process.env.REACT_APP_BASE_URL}/p2p/video-setup`, updatedData, config)
      .then((response) => {
        console.log('Response:', response);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // Pass the save function to the parent component
  useEffect(() => {
    onSave(handleMediaContent);
  }, [onSave, data]);



  const handleSubmit = async (selectedQuality) => {
    setLoading(true); // Start loading spinner or state

    try {
      // Send request to backend API to update quality
      await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/updateQuality`, {
        quality: selectedQuality, // Use the parameter value directly
        deviceid: deviceId, // Pass the selected device ID
      });

      // Display success message with selected quality
      toast({
        title: 'Success!',
        description: `Video quality changed to ${selectedQuality}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Display error message if submission fails
      toast({
        title: 'Error',
        description: 'Failed to change video quality',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // Stop loading spinner or state
    }
  };
  return (
    <div className={styles.notify}>
      <div className={styles.checkboxContainer} style={{ color: '#7F56D9' }}>
        <label style={{ fontSize: '18px', fontWeight: 'bold' }}>Video Setting</label>
      </div>

      {/* Microphone Type */}
      <div style={{display:'flex'}}>
        <p style={{ flex: '0 0 150px', fontSize: '18px', fontWeight: 'bold' }}>Microphone Type</p>
      
          <select
          name="microphoneType"
          style={{width:'200px'}}
            className={styles.selectInput}
            value={data.microphoneType}
            onChange={handleInputChange}
          >
            <option value="Active Pickup">Active Pickup</option>
            <option value="Passive Mic">Passive Mic</option>
          </select>
        
      </div>

      {/* Profile */}
      {/* <div style={{display:'flex'}}>
        <p style={{ flex: '0 0 150px', fontSize: '18px', fontWeight: 'bold' }}>Profile</p>
      
          <select
            name="profile"
          className={styles.selectInput}
          style={{width:'200px'}}
            value={data.profile}
            onChange={handleInputChange}
          >
            <option value="H.264">H.264</option>
            <option value="H.265">H.265</option>
            <option value="H.264+">H.264+</option>
            <option value="H.265+">H.265+</option>
          </select>
        
      </div> */}

      <div className={styles.notificationItem}>
      <p style={{flex:'0 0 150px', fontSize: "18px", fontWeight: "bold" }}>Quality</p>
      <div className={styles.selectContainer}>
        <select
          value={quality}
          onChange={handleQualityChange}
          className={styles.selectInput}
          disabled={loading} // Disable while submitting to avoid multiple triggers
        >
          <option value="low">Low</option>
          <option value="mid">Mid</option>
          <option value="high">High</option>
        </select>
      </div>
    </div>


      <div className={styles.notificationItem}>
        <p style={{flex:'0 0 150px', fontSize: "18px", fontWeight: "bold" }}>Encode Type</p>
        <div className={styles.selectContainer}>
          <select
            name="encodeType"
            className={styles.selectInput}
            value={data.encodeType}
            onChange={handleInputChange}
          >
            <option value="video">video</option>
            <option value="audio&video">audio&video</option>
          </select>
        </div>
      </div>
 {/* Channel Name */}
      
 <div className={styles.notificationItem}>
        <p style={{flex:'0 0 150px', fontSize: "18px", fontWeight: "bold" }}>Channel Name</p>
        <div className={styles.selectContainer} >
          <input
            width="200px"
            type="text"
            name="channelName"
            value={data.channelName}
            onChange={handleInputChange}
            className={styles.inputField}
          />
        </div>
      </div>
      {/* Stream */}
      {/* <div style={{display:'flex'}}>
        <p style={{ flex: '0 0 150px', fontSize: '18px', fontWeight: 'bold' }}>Stream</p>
      
          <select
          name="stream"
          style={{width:'200px'}}
            className={styles.selectInput}
            value={data.stream}
            onChange={handleInputChange}
          >
            <option value="main stream">main stream</option>
            <option value="substream1">substream1</option>
          </select>
        
      </div> */}

      {/* Resolution */}
      {/* <div style={{display:'flex'}}>
        <p style={{ flex: '0 0 150px', fontSize: '18px', fontWeight: 'bold' }}>Resolution</p>
        
          <select
            name="resolution"
            style={{width:'200px'}}
            className={styles.selectInput}
            value={data.resolution}
            onChange={handleInputChange}
          >
            <option value="2304x1296">2304x1296</option>
            <option value="1920x1080">1920x1080</option>
            <option value="1280x720">1280x720</option>
          </select>
        
      </div> */}

      {/* Bitrate Control Type */}
      {/* <div style={{display:'flex'}}>
        <p style={{ flex: '0 0 150px', fontSize: '18px', fontWeight: 'bold' }}>Bitrate Control Type</p>
      
          <select
            name="bitRateControlType"
            style={{width:'200px'}}
            className={styles.selectInput}
            value={data.bitRateControlType}
            onChange={handleInputChange}
          >
            <option value="vbr">VBR</option>
            <option value="cbr">CBR</option>
          </select>
           </div> */}

     

      {/* BPS */}
      {/* <div style={{display:'flex'}}>
        <p style={{ flex: '0 0 120px', fontSize: '18px', fontWeight: 'bold' }}>BPS</p>
        <div className={styles.inputContainer}>
          <input
            type="text"
            width="200px"
            name="constantBitRate"
            value={data.constantBitRate}
            onChange={handleInputChange}
            className={styles.inputField}
          />
        </div>
      </div> */}

      {/* FPS */}
      {/* <div style={{display:'flex'}}>
        <p style={{ flex: '0 0 120px', fontSize: '18px', fontWeight: 'bold' }}>FPS</p>
        <div className={styles.inputContainer}>
          <input
            type="text"
            width="200px"
            name="frameRate"
            value={data.frameRate}
            onChange={handleInputChange}
            className={styles.inputField}
          />
        </div>
      </div> */}

      {/* Save Button */}
      <div className={styles.saveButton}>
        <button colorScheme="purple" onClick={handleMediaContent}>Save</button>
      </div>
    </div>

  );
}

export default Video_Setup;
