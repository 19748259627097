import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';
import { Checkbox } from '@chakra-ui/react';

function OSD_Setup({ deviceId, onSave }) {
  const [data, setData] = useState({
    datetimeOverlay: {
      enabled: false,
      dateFormat: "",
      timeFormat: "",
      regionX: "",
      regionY: "",
      displayWeek: false,
    },
    channelNameOverlay: {
      enabled: false,
      regionX: "",
      regionY: "",
    },
  });
  const [dateFormat, setDateFormat] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [datetimeOverlayEnabled, setDatetimeOverlayEnabled] = useState(false);
  const [channelNameOverlayEnabled, setChannelNameOverlayEnabled] =
    useState(false);
  const [displayWeek, setDisplayWeek] = useState(false); // Add state for display week

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/osd-settings`,
          {
            params: { deviceId: deviceId },
          }
        );
        setData(response.data);
        setDateFormat(response.data.datetimeOverlay.dateFormat);
        setTimeFormat(response.data.datetimeOverlay.timeFormat);
        setDatetimeOverlayEnabled(response.data.datetimeOverlay.enabled);
        setChannelNameOverlayEnabled(response.data.channelNameOverlay.enabled);
        setDisplayWeek(response.data.datetimeOverlay.displayWeek); // Set initial display week state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [deviceId]);

  const handleDateFormatChange = (e) => setDateFormat(e.target.value);
  const handleTimeFormatChange = (e) => setTimeFormat(e.target.value);
  const handleDatetimeOverlayEnabledChange = (e) =>
    setDatetimeOverlayEnabled(e.target.checked);
  const handleChannelNameOverlayEnabledChange = (e) =>
    setChannelNameOverlayEnabled(e.target.checked);
  const handleDisplayWeekChange = (e) => setDisplayWeek(e.target.checked); // Handle display week change

  const handleOSDClick = async () => {
    const parsedTimeFormat =
      parseInt(timeFormat, 10) || parseInt(data.datetimeOverlay.timeFormat, 10);

    const requestBody = {
      datetimeOverlay: {
        enabled: datetimeOverlayEnabled,
        dateFormat: dateFormat || data.datetimeOverlay.dateFormat,
        timeFormat: parsedTimeFormat,
        regionX: data.datetimeOverlay.regionX,
        regionY: data.datetimeOverlay.regionY,
        displayWeek: data.displayWeek, // Use state value for display week
      },
      channelNameOverlay: {
        enabled: channelNameOverlayEnabled,
        regionX: data.channelNameOverlay.regionX,
        regionY: data.channelNameOverlay.regionY,
      },
      deviceIDOverlay: { enabled: false, regionX: 0, regionY: 0 },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/osd-settings`,
        requestBody,
        {
          params: { deviceId: deviceId },
        }
      );
      console.log("API response:", response);
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  return (
    <div className={styles.notify}>
      {/* IR Mode selection */}

      <div
        className={styles.checkboxContainer}
        style={{ marginBottom: "16px", color: "#7F56D9" }}
      >
        <label style={{ fontSize: "18px", fontWeight: "bold" }}>
          OSD Setup
        </label>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <label
          className={styles.overlayLabel}
          style={{ fontWeight: "bold", marginRight: "5%" }}
        >
          Date Time Overlay
        </label>
        <input
          type="checkbox"
          checked={datetimeOverlayEnabled}
          onChange={handleDatetimeOverlayEnabledChange}
        />
      </div>

      <div
        style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}
      >
        <label
          className={styles.overlayLabel}
          style={{ fontWeight: "bold", marginRight: "16px" }}
        >
          Channel Name Overlay
        </label>
        <input
          type="checkbox"
          checked={channelNameOverlayEnabled} // Corrected to channelNameOverlayEnabled
          onChange={handleChannelNameOverlayEnabledChange}
        />
      </div>

      <div
        style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}
      >
        <select
          value={dateFormat}
          onChange={handleDateFormatChange}
          style={{
            padding: "8px 18px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "14px",
            color: "#333",
            backgroundColor: "#fff",
          }}
        >
          <option value="DD/MM/YYYY">DD-MM-YYYY</option>
          <option value="MM/DD/YYYY">MM-DD-YYYY</option>
          <option value="YYYY/MM/DD">YYYY-MM-DD</option>
        </select>
      </div>

      <div
        style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}
      >
        <select
          value={timeFormat}
          onChange={handleTimeFormatChange}
          style={{
            padding: "8px 18px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "14px",
            color: "#333",
            backgroundColor: "#fff",
          }}
        >
          <option value="12">12-hour</option>
          <option value="24">24-hour</option>
        </select>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <Checkbox isChecked={displayWeek} onChange={handleDisplayWeekChange}>
          Display Week
        </Checkbox>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <button
          onClick={handleOSDClick}
          style={{
            backgroundColor: "#7F56D9",
            color: "white",
            padding: "4px 8px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontWeight: "bold",
            marginLeft: "10px",
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}

export default OSD_Setup;
