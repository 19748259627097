import styles from './MultiScreenDesktop.module.css';
import ScreenCard from './ScreenCard';
import React, { useEffect, useState } from 'react';
import { getCustomerCameraList } from '../../services/getcamera';
import { logout as apiLogout } from '../../services/auth';
import { Grid, Select } from '@chakra-ui/react';

const fetchData = async (page, updateOnlyStatus = false) => {
  const customerId = localStorage.getItem('userId');
  let resultPerPage = 9;
  if (window.innerWidth > 1645) {
    resultPerPage = 12; // Set to 12 if the screen width is greater than 1645px
  }

  try {
    // Fetch status data
    const statusResponse = await fetch('https://adiance-portal-backend-7d9tj.ondigitalocean.app/status');
    const statusData = await statusResponse.json();
    console.log('Status Data:', statusData);
    if (!updateOnlyStatus) {
      // Fetch camera list data
      const result = await getCustomerCameraList(customerId, page, resultPerPage);
      if (result.success) {
        // Merge the status data with camera list data
        const updatedCameraList = result.cameras.map(camera => {
          const matchingStatus = statusData.proxies.find(proxy =>
            proxy.name.trim().toLowerCase() === camera.deviceid.trim().toLowerCase()
          );
          return {
            ...camera,
            status: matchingStatus && matchingStatus.status === 'online' ? 'connected' : 'disconnected'
          };
        });
        return updatedCameraList;
      }
    }
  } catch (error) {
    console.error('Error fetching camera list:', error);
  }
  return [];
};

function MainContent() {
  const [devices, setDevices] = useState([]);
  const [screenCount, setScreenCount] = useState(6);
  console.log(devices)

  useEffect(() => {
    const loadDevices = async () => {
      const data = await fetchData(1);
      setDevices(data);
    };

    loadDevices();

    // Update status periodically
    const statusInterval = setInterval(() => {
      fetchData(1, true).then((data) => {
        setDevices((prevDevices) =>
          prevDevices.map((device) => {
            const updatedDevice = data.find((d) => d.deviceid === device.deviceid);
            return updatedDevice ? { ...device, status: updatedDevice.status } : device;
          })
        );
      });
    }, 5000);

    return () => clearInterval(statusInterval);
  }, []);

  // Filter devices to show only those with status 'connected'
  const onlineDevices = devices.filter(device => device.status === 'connected');

 // Handler for screen count selection
 const handleScreenCountChange = (event) => {
  setScreenCount(Number(event.target.value));
};

  // const screens = [
  //   { id: 1, image: "https://cdn.builder.io/api/v1/image/assets/TEMP/5798bbe4a356cb80f21d8245f334dd4f937e7aac819df3de5163a56d92b5f815?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", title: "Godown No 11 Gate" },
  //   { id: 2, image: "https://cdn.builder.io/api/v1/image/assets/TEMP/307f5ddf981ebcdf35e9e3f960bd6c779ce262657fe65e3ef1c7022f44fa2f35?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", title: "Godown No 11 Gate" },
  //   { id: 3, image: "https://cdn.builder.io/api/v1/image/assets/TEMP/2f8f7360816bf7840c1cdfd1c98fa9daa82278e819071a8fa355d97a9de90604?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", title: "Godown No 11 Gate" },
  //   { id: 4, image: "https://cdn.builder.io/api/v1/image/assets/TEMP/3af8f74b84e014aa899518ddba93dd21dace4c6fd68ecbb8ac2a17643e53228f?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", title: "Godown No 11 Gate" }
  // ];

  return (
    <section className={styles.mainContent}>
      <div className={styles.contentHeader}>
        <div>
          <h1 className={styles.title}>Multi Screen</h1>
          <p className={styles.description}>Priority based screen broadcasting for more intense monitoring</p>
        </div>
        <div className={styles.screenSelector}>
          <Select onChange={handleScreenCountChange} defaultValue={6} width="150px">
            <option value={6}>6 screens</option>
            <option value={8}>8 screens</option>
            <option value={10}>10 screens</option>
          </Select>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a73a33f8ddfa7ead167c5c113244961e26c3af2e4e3e2fda8a031ef97b2bad82?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Full screen" className={styles.fullscreenIcon} />
        </div>
      </div>
      <Grid templateColumns={`repeat(${screenCount / 2}, 1fr)`} gap={6} w="full" p={4}>
        {onlineDevices.slice(0, screenCount).map(screen => (
          <ScreenCard key={screen.id} image={'https://cdn.builder.io/api/v1/image/assets/TEMP/3af8f74b84e014aa899518ddba93dd21dace4c6fd68ecbb8ac2a17643e53228f?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'} title={screen.cameraname}
            deviceid={screen.deviceid} plan={screen.planname} />
        ))}
      </Grid>
    </section>
  );
}

export default MainContent;



