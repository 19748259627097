import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';
import { Checkbox, Select } from '@chakra-ui/react';

function ISP_Setup({ deviceId, onSave }) {
  const [isChecked, setIsChecked] = useState(false);
  const [irMode, setIrMode] = useState(); // Default value

  // Fetch the current IR mode when the component mounts
  useEffect(() => {
    const fetchIrMode = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/p2p/ir-setup`, {
          params: { deviceId: deviceId },
        });
        const currentIrMode = response.data.irCutFilter.irCutMode;
        console.log("currentIrMode",currentIrMode)
        setIrMode(currentIrMode);
      } catch (error) {
        console.error('Error fetching IR mode:', error.response ? error.response.data : error.message);
      }
    };

    fetchIrMode();
  }, [deviceId]);

  const handleIrModeChange = async (event) => {
    // Check if event exists to prevent undefined access
    const selectedValue = event?.target?.value || irMode; // Fallback to current irMode if no event
  
    setIrMode(selectedValue); // Update the state with the new selection
  
    const imageConfig = {
      irCutFilter: {
        irCutMode: selectedValue,
      },
    };
  
    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/ir-setup`, imageConfig, {
        params: { deviceId: deviceId },
        headers: { 'Content-Type': 'application/json' },
      });
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };
  const handleDigitalCheckboxChange = async (event) => {
    const checked = event.target.checked;
    setIsChecked(checked);

    const enable = { enable: checked };

    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/dwdr`, enable, {
        params: { deviceId: deviceId },
        headers: { 'Content-Type': 'application/json', Authorization: 'Basic YWRtaW46' }, // Base64 encoding of "admin:"
      });
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  // Pass the save function to the parent component
  useEffect(() => {
    if (onSave) {
      // Pass a default value to handleIrModeChange when called without an event
      onSave(() => handleIrModeChange({ target: { value: irMode } }));
    }
  }, [onSave, irMode]);
  return (
    <div className={styles.notify}>
      {/* IR Mode selection */}
      <div className={styles.checkboxContainer} style={{ marginBottom: '16px', color: '#7F56D9' }}>
        <label style={{ fontSize: '18px', fontWeight: 'bold' }}>ISP Setup</label>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ flex: '0 0 150px', fontWeight: 'bold', marginRight: '12px' }}>IR Mode</p>
        <Select value={irMode} onChange={handleIrModeChange} style={{ width: '200px' }}>
          <option value="irledmode">IR LED Mode</option>
          <option value="lightmode">Light Mode</option>
          <option value="smart">Smart Mode</option>
          <option value="daylight">Daylight Mode</option>
          <option value="night">Night Mode</option>
        </Select>
      </div>

      {/* Digital Width Dynamic Setting (DWDR) */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ flex: '0 0 150px', fontWeight: 'bold', marginRight: '16px' }}>Digital Width Dynamic Setting</p>
        <Checkbox isChecked={isChecked} onChange={handleDigitalCheckboxChange} />
      </div>
    </div>
  );
}

export default ISP_Setup;
