import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import withAuth from '../components/withAuth';
import {
  Box,
  Text,
  VStack,
  HStack,
  Flex,
  Switch,
  Icon,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  ButtonGroup,
  Stack,
  GridItem,
  Textarea,
  Heading,
  Grid,
  InputGroup,
  InputLeftAddon,
  useColorModeValue,
  useColorMode,
  RadioGroup,
  Radio,
  // Select,
} from '@chakra-ui/react';
import axios from 'axios';
import {
  FaArrowRight,
  FaBell,
  FaBolt,
  FaCheck,
  FaFileInvoice,
  FaInfo,
  FaInfoCircle,
  FaLayerGroup,
  FaLock,
} from "react-icons/fa";
import { getAIPlans, getDidForPlan, getFinalPrice, getPlans } from '../actions/dashboardActions';
import Select from 'react-select';
import { getCustomerCameraList } from '../services/getcamera';

const Subscription = () => {
  const router = useNavigate();
  const [offCameraCount, setOffCameraCount] = useState(0);
  const [onCameraCount, setOnCameraCount] = useState(0);
  const [metricss, setMetricss] = useState([]);
  const [metricsss, setMetricsss] = useState([]);
  const [cameraIds, setCameraIds] = useState([]);


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const fetchData = async () => {
    const result = await getPlans();
    console.log("samjho", result);
    setSubscriptionPlans(result.data);
  }

  useEffect(() => {

    fetchData();
    // fetchAiData();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // payment function

  // const handlePayment = async (amount) => {
  //   try {
  //     if (!amount) {
  //       console.error("Amount is not provided");
  //       return;
  //     }

  //     const orderResponse = await axios.post('http://localhost:5000/create-order', {
  //       amount: amount, // Amount in the smallest currency unit (e.g., paise for INR)
  //       currency: 'INR',
  //       receipt: `order_rcptid_${Date.now()}`,
  //       notes: {
  //         key1: 'value1',
  //         key2: 'value2'
  //       }
  //     });

  //     const { id: order_id, currency, amount: orderAmount } = orderResponse.data;

  //     const options = {
  //       key: 'rzp_test_zpF8O1fVMcF0qN',
  //       amount: orderAmount,
  //       currency: currency,
  //       name: 'Your App Name',
  //       description: 'Test Transaction',
  //       order_id: order_id,
  //       handler: async (response) => {
  //         const paymentResponse = await axios.post('http://localhost:5000/verify-payment', {
  //           order_id: response.razorpay_order_id,
  //           razorpay_payment_id: response.razorpay_payment_id,
  //           razorpay_signature: response.razorpay_signature
  //         });

  //         if (paymentResponse.data.success) {
  //           // Create an invoice
  //           const customerDetails = {
  //             name: 'Hitu Singh',
  //             email: 'hitu.singh@example.com',
  //             contact: '9999999999'
  //           };

  //           const invoiceResponse = await axios.post('http://localhost:5000/create-invoice', {
  //             amount: orderAmount,
  //             currency: currency,
  //             customer_details: customerDetails
  //           });

  //           const { id: invoice_id } = invoiceResponse.data;

  //           alert('Payment Successful. Your invoice is being generated.');

  //           // Fetch the invoice
  //           const fetchInvoiceResponse = await axios.get(`http://localhost:5000/invoice/${invoice_id}`);
  //           const invoiceUrl = fetchInvoiceResponse.data.short_url;

  //           // Display the invoice link
  //           window.location.href = invoiceUrl;
  //         } else {
  //           alert('Payment Verification Failed');
  //         }
  //       },
  //       prefill: {
  //         name: 'Hitu Singh',
  //         email: 'hitu.singh@example.com',
  //         contact: '9999999999'
  //       },
  //       notes: {
  //         address: 'Razorpay Corporate Office'
  //       },
  //       theme: {
  //         color: '#F37254'
  //       }
  //     };

  //     const rzp = new window.Razorpay(options);
  //     rzp.open();
  //   } catch (error) {
  //     console.error("Error during payment", error);
  //   }
  // };

  const keyy = process.env.encrypt_key;
  const iv = process.env.iv;
  const keyyd = process.env.decrypt_key;
  const d_iv = process.env.d_iv;

  async function decrypt(encryptedText) {
    let decipher = crypto.createDecipheriv('aes-256-cbc', keyy, iv);
    let decrypted = decipher.update(encryptedText, 'hex', 'utf-8');
    decrypted += decipher.final('utf-8');
    return decrypted;
  }

  async function encrypt(text) {
    let cipher = crypto.createCipheriv('aes-256-cbc', keyyd, d_iv);
    let encrypted = cipher.update(text, 'utf-8', 'hex');
    encrypted += cipher.final('hex');
    return encrypted;
  }

  // date format code

  const currentTime = new Date();
  const formattedDate = currentTime.toLocaleDateString('en-GB');

  // Parse formattedDate back to a Date object
  const parts = formattedDate.split('/');
  const date = new Date(parts[2], parts[1] - 1, parts[0]);

  // Add 28 days to the date
  date.setDate(date.getDate() + 28);

  // Format the new date back to 'en-GB' format
  const formattedEndDate = date.toLocaleDateString('en-GB');

  //  payment function

  const handlePayment = async (amount) => {
    try {
      if (!amount) {
        console.error("Amount is not provided");
        return;
      }
      let userid = localStorage.getItem("userId");
      // const decryptedUserId = await decrypt(userid);
      // const encryptedUserId = await encrypt(decryptedUserId);
      // Create order
      const orderResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/pay/create-order`, {
        amount: amount * 100, // Amount in the smallest currency unit (e.g., paise for INR)
        currency: 'INR',
        receipt: `order_rcptid_${Date.now()}`,
        notes: {
          key1: 'value1',
          key2: 'value2'
        },
        customerid: userid,
        shippingInfo: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          pinCode: formData.pincode,
          phoneNo: formData.contact
        },
        orderItems: [
          {
            cameraName: 'Camera 1',
            cameraType: 'Type A',
            quantity: 2,
            price: 100,
            planName: selectedPlan.name,
            featuresList: selectedAIOptions,
            planDays: 28,
            planStartDate: formattedDate,
            planEndDate: formattedEndDate,
            deviceId: selectedOptions,
            storagePlan: selectedPlanOptions,
          }
        ]
      }, { withCredentials: true });

      const { id: order_id, currency, amount: orderAmount } = orderResponse.data;

      const options = {
        key: 'rzp_test_zpF8O1fVMcF0qN', // Add your Razorpay key here
        amount: orderAmount,
        currency: currency,
        name: formData.firstName + formData.lastName,
        description: 'Test Transaction',
        order_id: order_id,
        handler: async (response) => {
          try {
            // Verify payment
            const paymentResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/pay/verify-payment`, {
              order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature
            },
              { withCredentials: true });

            if (paymentResponse.data.success) {
              
              // Log payment details to the console
              console.log('Payment Details:', {
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                amount: orderAmount,
                currency: currency,
                notes: {
                  key1: 'value1',
                  key2: 'value2'
                },
                status: 'successful'
              });
              alert('Payment Successful');
            } else {
              
              // Log failed payment details to the console
              console.log('Failed Payment Details:', {
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                amount: orderAmount,
                currency: currency,
                notes: {
                  key1: 'value1',
                  key2: 'value2'
                },
                status: 'failed'
              });
              alert('Payment Verification Failed');
            }
          } catch (error) {
            console.error('Error verifying payment:', error);
          }
        },
        prefill: {
          name: formData.firstName + formData.lastName,
          email: formData.email,
          contact: formData.contact
        },
        notes: {
          address: 'VMUKTI OFFICE'
        },
        theme: {
          color: '#F37254'
        }
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
      setIsModalOpen(false);
      setSelectedAIOptions([]);
      setAiPrice(0);
    } catch (error) {
      console.error("Error during payment", error);
    }
  };

  // NEW NAITIK CODE

  const [isAnnual, setIsAnnual] = useState(true);
  const [includeAI, setIncludeAI] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedAIOptions, setSelectedAIOptions] = useState([]);
  const [selectedPlanOptions, setSelectedPlanOptions] = useState('DVR1');
  const [aiPrice, setAiPrice] = useState(0);

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bgColor = useColorModeValue("white", "gray.800");
  const boxShadowColor = useColorModeValue("lg", "2xl");

  const handleAnnualToggle = () => setIsAnnual(!isAnnual);

  const [aisubscriptionPlans, setAiSubscriptionPlans] = useState([]);
  const [storagePlan, setStoragePlan] = useState([]);
  const [didDropdown, setDidDropdown] = useState([]);
  const [didForPlan, setDidForPlan] = useState('');

  const options =
  didDropdown && didDropdown.length > 0
    ? didDropdown.map((did) => ({
        value: did,   // `did` is the deviceid
        label: did,   // Use the deviceid as the label as well
      }))
    : [];
   

  // State to store selected options
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Handler for when options are selected
  const handleChange = (selected) => {
    setSelectedOptions(selected);
    handleCategoryChange(selected); // Pass the selected options to your handler
  };

  const plan =
    storagePlan && storagePlan.length > 0
      ? storagePlan.map((did) => ({
        value: did.planName
      }))
      : [];

  // delete below code

  // State to store selected options
  // const [planOptions, setPlanOptions] = useState([]);

  // // Handler for when options are selected
  // const handlePlanChange = (selected) => {
  //   // setSelectedOptions(selected);
  //   setSelectedPlanOptions(selected); // Pass the selected options to your handler
  //   // console.log("55555555555", selected);
  // };

  // delete above code


  const handlePlanOptionChange = (selectedValue) => {
    setSelectedPlanOptions(selectedValue); // selectedValue will be the new selected plan
  };


  const openModal = async (plan) => {
    let userid = localStorage.getItem("userId");
    // const decryptedUserId = await decrypt(userid);
    // const encryptedUserId = await encrypt(decryptedUserId);

    const result = await getAIPlans(plan.name);
    const result2 = await getCustomerCameraList(userid,1,1000);
    setDidDropdown(result2.cameras.map(camera => camera.deviceid));
console.log(result2.cameras.map(camera => camera.deviceid))
    setAiSubscriptionPlans(result.data[0].aiFeatures);
    setStoragePlan(result.data[0].storagePlan);
    console.log("yogo", result.data[0].storagePlan);
    // }
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAIOptions([]);
    setSelectedPlanOptions([]);
    setAiPrice(0);
  };

  const closeAddressModal = () => {
    setIsAddressModalOpen(false);
  };
  const handleAIOptionChange = (e) => {
    const value = e.target.value;
    setSelectedAIOptions((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value]
    );
  };
  // const handlePlanOptionChange = (e) => {
  //   const value = e.target.value;
  //   setSelectedPlanOptions((prev) =>
  //     prev.includes(value)
  //       ? prev.filter((option) => option !== value)
  //       : [...prev, value]
  //   );
  // };


  const [finalAmt, setFinalAmt] = useState(0);
  const handleProceedToAddress = async () => {
    const planType = isAnnual ? 'Annually' : 'monthly';
    // Close the first modal and open the address modal
    const result = await getFinalPrice(selectedPlan.name, planType, selectedAIOptions, selectedPlanOptions);
    setFinalAmt(result.data);
    setIsModalOpen(false);
    setIsAddressModalOpen(true);
  };

  const calculatePrice = (plan) => {
    if (!plan) return 0;
    let price = isAnnual ? plan.annually : plan.monthly;
    if (includeAI) {
      price += aiPrice;
    }
    return price;
  };

  // const updateAiPrice = () => {
  //   const totalAiPrice = selectedAIOptions.reduce((acc, option) => {
  //     const feature = aiFeaturesList.find((f) => f.id === parseInt(option));
  //     if (feature) {
  //       // Use monthly or annual price based on the billing cycle
  //       return (
  //         acc + (isAnnual ? feature.aiAnnuallyPrice : feature.aiMonthlyPrice)
  //       );
  //     }
  //     return acc;
  //   }, 0);
  //   setAiPrice(totalAiPrice);
  // };

  useEffect(() => {
    const updateTotalPrice = () => {
      // Calculate AI Options Price
      const totalAiPrice = selectedAIOptions.reduce((acc, option) => {
        const feature = aisubscriptionPlans.find((f) => f.name === option);
        if (feature) {
          return acc + (isAnnual ? feature.aiAnnuallyPrice : feature.aiMonthlyPrice);
        }
        return acc;
      }, 0);
  
      // Calculate DVR Plan Price
      const selectedDvrPlan = storagePlan.find(
        (plan) => plan.planName === selectedPlanOptions
      );
      const dvrPlanPrice = selectedDvrPlan
        ? selectedDvrPlan.price
        : 0;
  
      // Add both AI options price and DVR plan price
      const totalPrice = totalAiPrice + dvrPlanPrice;
  
      // Update the AI price
      setAiPrice(totalPrice);
    };
  
    updateTotalPrice();
  }, [selectedAIOptions, isAnnual, selectedPlanOptions, storagePlan]); // Add selectedPlanOptions to dependency array
  

  // form button disabled
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    contact: ''
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  // Check if all form fields are filled
  const isFormComplete = Object.values(formData).every((field) => field.trim() !== '');

  // did value for sending in handlepayment
  // const handleCategoryChange = (e) => {
  //   const value = e.target.value;
  //   setDidForPlan(value);
  // };

  const handleCategoryChange = (selectedOptions) => {
    // If it's a multi-select, selectedOptions will be an array.
    // If it's a single select (without `isMulti`), it would be a single object.
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];

    setDidForPlan(values); // Update the state with the array of selected subdeviceid values
  };

  return (
    <>
      <Box
        // ml={{ base: '2%', md: '10%', lg: '16%' }}
        mr={{ base: '2%', md: '5%', lg: '9%' }} mt={'3%'}>

        {/* <DesktopHeader /> */}
        {/* <Box
                width='100%'
                // bg="gray.50"
                _dark={{ bg: "gray.900" }}
                minH="100vh"
                display="flex"
                flexDirection="column"
                position='fixed' left={'72px'} top={'64px'}
                overflowY="auto" maxH="calc(100vh - 60px)"
                p={4}
              > */}
        {/* Header Section */}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="full"
          maxWidth="100%"
          mb={8}
        >
          {/* Left Side: Subscription Texts */}
          <VStack width='100%' alignItems="center" spacing={1}>
            <Text
              fontSize={{ base: "2xl", md: "4xl" }} // base for mobile, md for larger screens
              fontWeight="bold"
              color="gray.800"
            >
              Subscription
            </Text>

            <Text
              fontSize="md"
              color="gray.600"
              display={{ base: "none", md: "block" }}
            >
              Select the plan that suits your demand. Hardware and accessibility
              are different in each plan.
            </Text>
          </VStack>

          {/* Right Side: View Invoices Link */}
          {/* <Link
                href="#"
                color="black.600" // More consistent with the purple theme
                fontWeight="semiBold"
                display="flex"
                alignItems="center"
                _hover={{ textDecoration: "underline" }}
              >
                <Icon as={FaFileInvoice} w={5} h={5} mr={2} />
                View invoices
                <Icon
                  as={FaArrowRight}
                  w={4}
                  h={4}
                  ml={1}
                  display={{ base: "none", md: "block" }}
                />
              </Link> */}
        </Flex>

        {/* Toggle Switches */}
        <ButtonGroup
          isAttached
          variant="outline"
          borderRadius="full"
          display={"flex"}
          alignItems="center"
          justifyContent="center"
          mb={7}
        >
          <Button
            onClick={() => handleAnnualToggle(true)}
            bg={isAnnual ? "purple.600" : "white"}
            color={isAnnual ? "white" : "black"}
            borderRadius="full"
            _hover={{ bg: isAnnual ? "black" : "gray.100" }}
          >
            Yearly
          </Button>
          <Button
            onClick={() => handleAnnualToggle(false)}
            bg={!isAnnual ? "purple.600" : "white"}
            color={!isAnnual ? "white" : "black"}
            borderRadius="full"
            _hover={{ bg: !isAnnual ? "black" : "gray.100" }}
          >
            Monthly
          </Button>
        </ButtonGroup>

        {/* Subscription Cards */}
        <Flex
          wrap="wrap"
          justify="center" // Center align for mobile view
          gap={6}
        >
          {subscriptionPlans.map((plan) => (
            <Box
              key={plan.id}
              bg="white"
              _dark={{ bg: "gray.800" }}
              border="1px"
              borderColor="gray.300"
              borderRadius="xl"
              shadow="md"
              _hover={{ shadow: "lg", transform: "scale(1.05)" }}
              transition="transform 0.3s"
              p={6}
              minW="250px"
              maxW="350px"
              // flexGrow={1}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              textAlign="center"
            >
              <VStack align="center" flexGrow={1}>
                {/* Plan Name and Icon */}
                <Box bg={`${plan.color}.100`} p={2} borderRadius="full">
                  <Icon as={plan.icon} w={6} h={6} color={`${plan.color}.500`} />
                </Box>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="gray.800"
                  _dark={{ color: "white" }}
                >
                  {plan.name}
                </Text>

                {/* Plan Price */}
                <VStack align="center">
                  <HStack>
                    <Text
                      fontSize="3xl"
                      fontWeight="bold"
                      color="gray.800"
                      _dark={{ color: "white" }}
                    >
                      ₹{isAnnual ? plan.annually : plan.monthly}
                    </Text>
                    <Text
                      fontSize="lg"
                      color="gray.500"
                      _dark={{ color: "gray.400" }}
                    >
                      {isAnnual ? "/year" : "/mth"}
                    </Text>
                  </HStack>
                </VStack>

                {/* Plan Features */}
                <VStack align="start" w="full" spacing={2} pt={4}>
                  {plan.features.map((feature, index) => (
                    <HStack key={index} align="center">
                      <Box bg={`${plan.color}.100`} p={1} borderRadius="full">
                        <Icon as={FaCheck} w={4} h={4} color={plan.color} />
                      </Box>
                      <Text
                        fontSize="sm"
                        color="gray.600"
                        _dark={{ color: "gray.300" }}
                      >
                        {feature}
                      </Text>
                    </HStack>
                  ))}
                </VStack>
              </VStack>

              {/* Button and Secured Payment */}
              <Button
                colorScheme={plan.color}
                variant={
                  plan.buttonText === "Current Plan" ? "outline" : "solid"
                }
                rounded="md"
                py={2}
                mt={4}
                width="100%"
                transition="all 0.2s"
                onClick={() => openModal(plan)}
              >
                {/* Subscribe Now */}
                {plan.name === "Basic" ? "FREE" : "Subscribe Now"}
              </Button>
            </Box>
          ))}
        </Flex>
        <Box mt={6} textAlign="center" px={4}>
          <Text fontSize="sm" color="gray.500">
            <Icon as={FaInfoCircle} w={5} h={5} color="gray.500" mr={3} />
            Note: To Add AI Feature Click Subscribe Now Button
          </Text>
        </Box>
        {/* AI Features Modal */}
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          size={{ base: "50%", md: "lg" }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Customize Your AI Plan</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={4} fontSize={{ base: "md", md: "lg" }}>
                Select AI options for the {selectedPlan?.name} plan:
              </Text>
              <FormControl mb={4}>
                <FormLabel fontSize={{ base: "sm", md: "md" }}>Select DID</FormLabel>
                {/* <Select
                  placeholder="Select DID"
                  mb={4}
                  size={{ base: "sm", md: "md" }}
                  onChange={handleCategoryChange} // Add your actual onChange handler here
                >
                  {didDropdown && didDropdown.cameralists && didDropdown.cameralists.length > 0 ? (
                    didDropdown.cameralists.map((did) => (
                      <option key={did.subdeviceid} value={did.subdeviceid}>
                        {did.subdeviceid}
                      </option>
                    ))
                  ) : (
                    <option disabled>No DID available</option>
                  )}
                </Select> */}
                <Select
                  placeholder="Select DID"
                  isMulti
                  value={selectedOptions}
                  options={options}
                  onChange={handleChange}
                  size={{ base: "sm", md: "md" }}
                />
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  AI Options
                </FormLabel>
                {aisubscriptionPlans.map((feature) => (
                  <VStack key={feature.id} spacing={2} align="start" mb={2}>
                    <Checkbox
                      value={feature.name}
                      isChecked={selectedAIOptions.includes(
                        feature.name
                      )}
                      onChange={handleAIOptionChange}
                      size={{ base: "sm", md: "md" }}
                    >
                      {feature.name} (+$
                      {isAnnual
                        ? feature.aiAnnuallyPrice
                        : feature.aiMonthlyPrice}
                      )
                    </Checkbox>
                  </VStack>
                ))}
                <FormLabel fontSize={{ base: "sm", md: "md" }}>
                  Storage Plans
                </FormLabel>
                {storagePlan && storagePlan.length > 0 ? (
                  <RadioGroup onChange={handlePlanOptionChange} value={selectedPlanOptions}>
                    {storagePlan.map((feature) => (
                      <VStack key={feature.id} spacing={2} align="start" mb={2}>
                        <Radio value={feature.planName} size={{ base: "sm", md: "md" }}>
                          {feature.planName}&nbsp;-&nbsp;₹{feature.price}
                        </Radio>
                      </VStack>
                    ))}
                  </RadioGroup>
                ) : (
                  <p>No storage plans available</p>
                )}

                {/* <Select
                  placeholder="Select Plan"
                  isMulti
                  value={selectedPlanOptions}
                  options={plan}
                  onChange={handlePlanChange}
                  size={{ base: "sm", md: "md" }}
                /> */}

              </FormControl>
              <VStack spacing={4} align="start">
                <Text fontSize={{ base: "md", md: "lg" }} fontWeight="bold">
                  Total AI Cost: ₹{aiPrice}
                </Text>
                <Text fontSize={{ base: "md", md: "lg" }} fontWeight="bold">
                  Updated Plan Price: ₹
                  {selectedPlan
                    ? calculatePrice(selectedPlan) + aiPrice
                    : aiPrice}
                </Text>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Stack spacing={4} w="100%">
                <Button
                  colorScheme="purple"
                  mr={3}
                  onClick={handleProceedToAddress}
                  size={{ base: "sm", md: "md" }}
                >
                  Proceed To Pay
                </Button>

              </Stack>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Address Modal */}

        <Modal isOpen={isAddressModalOpen} onClose={closeAddressModal} size={{ base: "50%", md: "lg" }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Shipping Address</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <GridItem>
                <Box
                  border="1px solid"
                  borderColor={borderColor}
                  borderRadius="lg"
                  boxShadow={boxShadowColor}
                  p={6} // Increase padding for form container
                  bg={bgColor}
                >
                  <form>
                    <Grid
                      templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                      gap={4}
                    >
                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <FormControl isRequired>
                          <FormLabel htmlFor="firstName">First Name</FormLabel>
                          <Input
                            id="firstName"
                            name="firstName"
                            autoComplete="given-name"
                            variant="outline"
                            focusBorderColor="purple.500"
                            placeholder="Enter your first name"
                            value={formData.firstName}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <FormControl isRequired>
                          <FormLabel htmlFor="lastName">Last Name</FormLabel>
                          <Input
                            id="lastName"
                            name="lastName"
                            autoComplete="family-name"
                            focusBorderColor="purple.500"
                            placeholder="Enter your last name"
                            value={formData.lastName}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={2}>
                        <FormControl isRequired>
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Input
                            id="email"
                            name="email"
                            autoComplete="email"
                            focusBorderColor="purple.500"
                            placeholder="Enter your Email"
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={2}>
                        <FormControl isRequired>
                          <FormLabel htmlFor="address">Street Address</FormLabel>
                          <Textarea
                            id="address"
                            name="address"
                            autoComplete="shipping street-address"
                            rows={3}
                            focusBorderColor="purple.500"
                            placeholder="Enter your address"
                            value={formData.address}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <FormControl isRequired>
                          <FormLabel htmlFor="city">City</FormLabel>
                          <Input
                            id="city"
                            name="city"
                            autoComplete="address-level2"
                            focusBorderColor="purple.500"
                            placeholder="Enter your city"
                            value={formData.city}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <FormControl isRequired>
                          <FormLabel htmlFor="state">State</FormLabel>
                          <Input
                            id="state"
                            name="state"
                            autoComplete="address-level1"
                            focusBorderColor="purple.500"
                            placeholder="Enter your state"
                            value={formData.state}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <FormControl isRequired>
                          <FormLabel htmlFor="pincode">Pin/Postal Code</FormLabel>
                          <Input
                            id="pincode"
                            name="pincode"
                            autoComplete="postal-code"
                            focusBorderColor="purple.500"
                            placeholder="Enter your PinCode"
                            value={formData.pincode}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <FormControl isRequired>
                          <FormLabel htmlFor="contact">Contact Number</FormLabel>
                          <InputGroup>
                            <InputLeftAddon children="+91" />
                            <Input
                              type="tel"
                              id="contact"
                              name="contact"
                              placeholder="Enter Contact"
                              autoComplete="tel"
                              focusBorderColor="purple.500"
                              value={formData.contact}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </form>
                </Box>
              </GridItem>
            </ModalBody>
            <ModalFooter>
              <Stack spacing={4} w="100%">
                <Button
                  colorScheme="purple"
                  mr={3}
                  onClick={() => handlePayment(finalAmt)}
                  size={{ base: "sm", md: "md" }}
                  isDisabled={!isFormComplete}
                >
                  Payment ₹{finalAmt}
                </Button>

                <Flex align="center" justify="center" mt={2}>
                  <Icon as={FaLock} w={4} h={4} color="green.500" mr={1} />
                  <Text
                    fontSize="xs"
                    color="green.500"
                    _dark={{ color: "gray.300" }}
                  >
                    Secured Payment
                  </Text>
                </Flex>
              </Stack>
            </ModalFooter>
          </ModalContent>
        </Modal>






        {/*  */}
        {/*  */}
        {/*  */}

      </Box>
    </>
  );
};

export default Subscription
