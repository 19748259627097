
import React, { useEffect, useState, useRef } from 'react';
import {
  sendQueryToBackend,
  chatHistory,
  summarizeVideo,
} from "../actions/userActions";
import { Avatar, Box, Button, Card, CardBody, Center, Grid, Heading, Skeleton, Text } from '@chakra-ui/react';
import axios from 'axios';
import { getCardDataByPrompt } from '../../actions/dashboardActions';
import AiImage from './aiimage';
import { MdAttachFile } from 'react-icons/md';
import { FaArrowUp } from "react-icons/fa6";
import { getCustomerCameraList } from '../../services/getcamera';

const ArcisGPT = () => {

  // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const customerId = localStorage.getItem('userId');
  const [selectedChat, setSelectedChat] = useState(false);
  const [selectedVideoSumm, setSelectedVideoSumm] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(false);
  const [selectedprompt, setSelectedPrompt] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [hovered, setHovered] = useState(false); // State to track hover
  const isMobile = window.innerWidth <= 768;
  const widthStyle = '100%';

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const fileInputRef = useRef(null);

  // Event listener to update device width on window resize
  const handleResize = () => {
    setDeviceWidth(window.innerWidth);
  };

  // Attach event listener on component mount
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAttachFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // const handlefileChange = (event) => {
  //   const files = event.target.files;
  //   if (files.length > 0) {
  //     console.log('Selected file:', files[0]);
  //   }
  // };

  const [showModal, setShowModal] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(null);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const handleImageClick = (imgUrl) => {
    setSelectedImage(imgUrl);
    setIsModalOpen(true);
  };

  const handleCloseModall = () => {
    setIsModalOpen(false);
  };

  const [showLogo, setShowLogo] = useState(true); // State to track whether to show logo or not
  const [showSampleQuestions, setShowSampleQuestions] = useState(true);

  const [messages, setMessages] = useState([
    // { text: 'Hi there! How can I help you?', sender: 'bot' }
  ]);
  useEffect(() => {
    if (messages.length > 0) {
      setShowLogo(false);
    } else {
      setShowLogo(true);
    }
  }, [messages]);
  const [input, setInput] = useState('');


  const handleMessageSubmit = async (e) => {
    console.log('handleMessageSubmit called');
    e.preventDefault();

    // if (input.trim() === '') return;

    // Add user message to chat history
    const updatedMessages = [...messages, { text: input, sender: 'user' }];
    setMessages(updatedMessages);
    setIsBotTyping(true);

    try {
      let botResponse;

      // Log values to debug
      console.log('Selected Video:', selectedVideo);
      console.log('Image File:', imageFile);

      if (selectedVideo) {
        if (imageFile) {
          console.log('Image File exists:', imageFile);
          const formData = new FormData();
          formData.append('image', imageFile); // Append the image to the form data

          const response = await axios.post('https://ai1.ambicam.com:9900/image-search', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          botResponse = response.data; // Capture the bot's response
          console.log('Image uploaded successfully:', botResponse);
        } else {
          console.log('Image file is missing.');
          botResponse = 'Please select an image to search.';
        }
      } else if (selectedChat) {
        const query = 'person';
        const dvr_plan = 'DVR-7';
        const camera_id = 'RTSP-VSPL-124805-EHWDH';
        botResponse = await sendQueryToBackend(input, customerId, query, dvr_plan, camera_id);
        console.log('Chat API called successfully:', botResponse);
      } else if (selectedVideoSumm) {
        const response = await summarizeVideo(input, customerId);
        botResponse = response.summary.replace(/###/g, '\n###');
        // console.log('Bot response:', response.summary.replace(/###/g, '\n###'));
        // Ensure to trim whitespace if necessary
        botResponse = botResponse.trim();
        setInput('');
      } else if (selectedprompt) {
        botResponse = 'Handling prompt...'; // Placeholder for actual implementation
      } else {
        console.log('No video selected or prompt specified.');
        botResponse = 'No video selected or prompt specified.';
      }

      // Append bot response to chat history while keeping user message intact
      const updatedMessagesWithBotResponse = [...updatedMessages, { text: botResponse, sender: 'bot' }];
      setMessages(updatedMessagesWithBotResponse);

      // Update chat history if necessary
      const response = await chatHistory();
      console.log('Chat history:', response.data);
    } catch (error) {
      console.error('Error handling message submission:', error);
    }

    // Clear input field after submitting message
    setInput('');
    setIsBotTyping(false);
    setShowSampleQuestions(false);
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // console.log('Selected File:', file); // Debugging log
    setImageFile(file);
  };

  const handleCardSubmit = async (message) => {
    // if (message.trim() === '') return;

    // // Add user message to chat history
    // const updatedMessages = [...messages, { text: message, sender: 'user' }];
    // setMessages(updatedMessages);

    // setIsBotTyping(true);

    // try {
    //   // Make API call to the backend with the user query
    //   const botResponse = await sendQueryToBackend(message);

    //   // Append bot response to chat history while keeping user message intact
    //   const updatedMessagesWithBotResponse = [...updatedMessages, { text: botResponse, sender: 'bot' }];
    //   setMessages(updatedMessagesWithBotResponse);
    //   const response = await chatHistory();
    //   console.log("chat history :: ", response.data)
    // } catch (error) {
    //   // Handle error if API call fails
    //   console.error('Error handling message submission:', error);
    // }

    // // Clear input field after submitting message
    // setInput('');
    // setIsBotTyping(false);
    // setShowSampleQuestions(false);
  };

  const [imageUrls, setImageUrls] = useState([]);
  const handleDashboardAlertClick = async (input) => {
    try {
      // Fetching the prompt data from the backend using input
      const getPromptCardData = await getCardDataByPrompt(input);
      // console.log('Fetched Data:', getPromptCardData.data.response.imageUrls);  

      // Assuming the structure of the response is similar to your earlier data, where image URLs are in the `imageUrls` array
      if (getPromptCardData && getPromptCardData.data.response && getPromptCardData.data.response.imageUrls) {
        // Extract the image URLs and update the state
        if (getPromptCardData && getPromptCardData.data && getPromptCardData.data.response) {
          const { imageUrls } = getPromptCardData.data.response;

          // Extract the third element from each imageUrls array
          const extractedPaths = imageUrls.map((imgUrl) => `mp4/${imgUrl[2]}`);


          // console.log(extractedPaths)
          setImageUrls(extractedPaths);
        } (getPromptCardData.response.imageUrls.map((imgUrl) => `mp4/${imgUrl[2]}`));
        // console.log(imageUrls)
      }
    } catch (error) {
      console.error('Error fetching prompt data:', error);
    }
  };

  // for suggestion
  // const [cameraNames, setCameraNames] = useState([]);
  // const [suggestion, setSuggestion] = useState('');
  // const inputRef = useRef(null);
  // useEffect(() => {
  //   const fetchCameraNames = async () => {
  //     const data = await getCustomerCameraList(customerId, 1, 1000); // Example API call
  //     if (data && data.cameras) {
  //       const cameraNames = data.cameras.map((camera) =>
  //         `${camera.cameraname}`
  //       );
  //       console.log("::", cameraNames)
  //       setCameraNames(cameraNames);
  //     }
  //   };

  //   fetchCameraNames();
  // }, [customerId]);
  // const handleInputChange = (e) => {
  //   const value = e.target.value;
  //   setInput(value);

  //   // Find all matches from the camera names that contain the input value
  //   const matches = cameraNames.filter((name) =>
  //     name.toLowerCase().includes(value.toLowerCase())
  //   );

  //   if (matches.length > 0 && value.length > 2) { // Check for more than 2 characters
  //     // Set the first match as the suggestion
  //     setSuggestion(matches[0]);
  //   } else {
  //     setSuggestion('');
  //   }
  // };
  // const handleKeyDown = (e) => {
  //   if (e.key === 'Tab' && suggestion) {
  //     // Prevent the default tab behavior
  //     e.preventDefault();
  //     // Set the input value to the suggestion when Tab is pressed
  //     setInput(suggestion);
  //     setSuggestion(''); // Clear the suggestion
  //   }
  // };

  return (
    <div className="chatbot-page-container" style={{ width: widthStyle }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: '10px', overflow: 'hidden', height: '100%' }}>
        <div className="chatbot-messages" style={{ padding: '10px', flex: 1, overflowY: 'auto', marginBottom: '20px', position: 'absolute', bottom: '8%', top: '60px' }}>
          {messages.map((msg, index) => {
            // Clean up the text by removing surrounding quotes and trailing commas
            const cleanedText = selectedChat
              ? msg.text.trim().replace(/^['"]+|['",]+$/g, '')
              : msg.text;

            return (
              <div
                key={index}
                className={`message ${msg.sender}`}
                style={{
                  marginBottom: '15px',
                  padding: '10px',
                  borderRadius: '5px',
                  backgroundColor: msg.sender === 'bot' ? '#f0f0f0' : '#4b93bf',
                  color: msg.sender === 'bot' ? '#333' : '#fff',
                  alignSelf: msg.sender === 'bot' ? 'flex-start' : 'flex-end'
                }}
              >
                {msg.sender === 'user' && (
                  <h6>
                    <Avatar size="sm" backgroundColor={'white'} name={'You'} mb="1" mr="2" />
                    <strong>You:</strong>
                  </h6>
                )}

                {msg.sender === 'bot' && (
                  <h6>
                    <Avatar src='./ArcisGPT1.png' size="sm" mr="2" mb="1" style={{ backgroundColor: 'white', borderColor: 'black', flexDirection: 'row', animation: 'upDown 1s infinite alternate' }} />
                    <strong>ArcisGPT:</strong>
                  </h6>
                )}

                {/* Render images directly if selectedVideo is true */}
                {selectedVideo ? (
                  msg.images && msg.images.map((url, i) => (
                    <img
                      key={i}
                      src={url.replace(/^['"]+|['",]+$/g, '')} // Ensure the URL is clean
                      alt={`Image ${i}`}
                      style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px', display: 'block', marginBottom: '10px' }}
                    />
                  ))
                ) : (
                  // Render text with image URLs if selectedChat is true
                  (cleanedText || '')
                    .replace(/###/g, '‣') // Replace ### with ‣
                    .replace(/-/g, '•')   // Replace - with •
                    .split('\n')          // Split by newline characters
                    .map((line, lineIndex) => (
                      <div key={lineIndex}>
                        {line.split(/(\*\*[^*]+\*\*)/).map((part, i) => {
                          // Check if part is a string before further checks
                          if (typeof part !== 'string') return null;

                          // Check if the part is bold text (i.e., surrounded by **)
                          const isBold = part.startsWith('**') && part.endsWith('**');
                          const cleanText = isBold ? part.slice(2, -2) : part; // Remove the asterisks for bold text

                          return isBold ? (
                            <strong key={i}>{cleanText}</strong> // Render bold text
                          ) : cleanText.includes('https://') && cleanText.includes('.jpg') ? (
                            <img
                              key={i}
                              src={cleanText.replace(/^['"]+|['",]+$/g, '')} // Remove any surrounding quotes
                              alt={`Image ${i}`}
                              style={{ maxWidth: '100%', height: 'auto', borderRadius: '5px', display: 'block', marginBottom: '10px' }}
                            />
                          ) : (
                            <span key={i}>{cleanText} </span> // Render normal text
                          );

                        })}
                      </div>
                    ))
                )}
              </div>
            );
          })}
          {isBotTyping && <Skeleton className="message bot"><span className="typing-animation"></span></Skeleton>}
        </div>

        {/* Chat Input */}
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          {showLogo && (
            <Center className="center-logo" p={'10px'} pb={0} flexDirection="column">
              <img src='./ArcisAi.png' style={{ height: '20px', marginTop: "18%", animation: 'upDown 1s infinite alternate' }} alt="Logo" />
              <Heading mt={4} fontWeight="bold" fontSize={20}>How can I help you today?</Heading>
              <br />
              <br />
              <br />
              {!isMobile && (
                <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                  <Box>
                    {/* <Card height="100%" cursor={'pointer'} borderColor="black" onClick={() => setSelectedChat(true)}>
                      <CardBody style={{ borderColor: 'rgba(0,0,0,.15)', borderRadius: 'inherit', borderWidth: 'thin', padding: '20px' }}>
                        <Button>Chat.</Button>&nbsp;&nbsp;
                        <Button>Video Summ.</Button>
                      </CardBody>
                    </Card> */}
                    <Card height="100%" cursor="pointer" borderColor="black">
                      <CardBody style={{ borderColor: 'rgba(0,0,0,.15)', borderRadius: 'inherit', borderWidth: 'thin', padding: '20px' }}>
                        <Button onClick={() => setSelectedChat(true)}>Chat</Button>&nbsp;&nbsp;
                        <Button onClick={() => setSelectedVideoSumm(true)}>Video Summ.</Button>
                      </CardBody>
                    </Card>

                  </Box>
                  <Box>
                    <Card height="100%" cursor={'pointer'} borderColor="black" onClick={() => setSelectedVideo(true)}>
                      <CardBody style={{ borderColor: 'rgba(0,0,0,.15)', borderRadius: 'inherit', borderWidth: 'thin', padding: '20px' }}>
                        <Button>Search from video.</Button>
                      </CardBody>
                    </Card>
                  </Box>
                  <Box>
                    <Card height="100%" cursor={'pointer'} borderColor="black" onClick={() => setSelectedPrompt(true)}>
                      <CardBody style={{ borderColor: 'rgba(0,0,0,.15)', borderRadius: 'inherit', borderWidth: 'thin', padding: '20px' }}>
                        <Button>Search with Prompt.</Button>
                      </CardBody>
                    </Card>
                  </Box>
                  <Box>
                    <Card height="100%" cursor={'pointer'} borderColor="black" onClick={() => handleCardSubmit()}>
                      <CardBody style={{ borderColor: 'rgba(0,0,0,.15)', borderRadius: 'inherit', borderWidth: 'thin', padding: '20px' }}>
                        <Button>Alert</Button>&nbsp;
                        <Button onClick={() => handleDashboardAlertClick(input)}>Dashboard-Alert</Button>
                      </CardBody>
                    </Card>
                  </Box>
                </Grid>
              )}
            </Center>
          )}
          <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={6} mt={4}>
            {/* Map through the imageUrls array and render each image using the AiImage component */}
            {imageUrls.length > 0 ? (
              imageUrls.map((imgUrl, index) => (
                <Card key={index}>
                  <CardBody>
                    <AiImage imageKey={imgUrl} />
                  </CardBody>
                </Card>
              ))
            ) : (
              <p>No images to display</p> // Fallback message if no images are available
            )}
          </Grid>
          <form onSubmit={handleMessageSubmit} className="chatbot-input-form" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: isMobile ? '100%' : '50%', position: 'fixed', bottom: !isMobile ? '20px' : '85px' }}>
            <div style={{ position: 'relative', flex: 1, margin: "0" }}>
              {/* Attach file icon (on the left side, inside the input) */}
              {imageFile ? (
                <img
                  src={URL.createObjectURL(imageFile)} // Display the selected image
                  alt="Selected"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px', // Move it to the left
                    width: '20px',
                    height: '20px',
                    objectFit: 'cover',
                    borderRadius: '5px',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer'
                  }}
                  onClick={() => setImageFile(null)} // Clear the image on click if needed
                />
              ) : (
                <MdAttachFile
                  fontSize={20}
                  className="attachIcon"
                  onClick={handleAttachFileClick} // This opens the hidden file input
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '10px', // Position it to the left
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                    backgroundColor: 'rgba(127,86,217,0.3)',
                    borderRadius: '60%',
                    width: '30px',
                    height: '30px',
                    padding: '0.5%',
                    color: 'rgba(0,0,0,0.5)',
                    zIndex: '99999'
                  }}
                />
              )}

              {/* Input text field (between the two icons) */}
              <input
                type="text"
                placeholder="Ask me anything..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                style={{
                  width: '100%',
                  padding: '10px 40px 10px 45px', // Padding on both sides for icons
                  borderRadius: '50px',
                  border: '1px solid rgba(127,86,217,0.5)',
                  fontSize: '16px',
                }}
              />
              {/* <input
                ref={inputRef}
                type="text"
                placeholder="Ask me anything..."
                value={input}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                style={{
                  width: '100%',
                  padding: '10px 40px 10px 45px',
                  borderRadius: '50px',
                  border: '1px solid rgba(127,86,217,0.5)',
                  fontSize: '16px',
                  position: 'relative',
                  zIndex: 1,
                  background: 'transparent',
                }}
              />
              {suggestion && suggestion.toLowerCase().includes(input.toLowerCase()) && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: `${input.length * 8 + 50}px`, // Adjust the left based on input length and padding
                    transform: 'translateY(-50%)',
                    fontSize: '16px',
                    color: 'rgba(127, 127, 127, 0.5)', // Light color for suggestion
                    pointerEvents: 'none',
                    whiteSpace: 'nowrap',
                    zIndex: 0,
                  }}
                >
                  <span style={{ color: 'rgba(127,86,217,0.7)' }}>
                    {suggestion.slice(input.length)}
                  </span>
                </div>
              )} */}

              {/* Up arrow icon (on the right side, inside the input) */}
              <FaArrowUp
                fontSize={20}
                className="arrowIcon"// Handle the arrow click as needed
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px', // Keep it on the right
                  transform: 'translateY(-50%)',
                  backgroundColor: hovered ? 'rgb(127,86,217,0.6)' : 'rgba(127,86,217,0.4)',
                  cursor: 'pointer',
                  borderRadius: '60%',
                  width: '30px',
                  height: '30px',
                  padding: '1%',
                  color: 'rgba(0,0,0,0.5)',
                  zIndex: '99999'
                }}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={handleMessageSubmit}
              />

              {/* Hidden file input */}
              <input
                type='file'
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </div>

            {/* Send button */}
            {/* <button
              type="submit"
              style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#4b93bf', color: '#fff', border: 'none', fontSize: '16px', cursor: 'pointer', marginLeft: '10px' }}>
              Send
            </button> */}
            {/* <div>
              <h5>ChatGPT can make mistakes. Check important info.</h5>
            </div> */}
          </form>

        </Box>
      </div>
    </div>


  );
};

export default ArcisGPT;