import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Image,
  Spinner,
  Box,
  Center,
  useToast,
} from '@chakra-ui/react';

const ImageGalleryModal = ({ isOpen, onClose, images }) => {
  console.log(images)
  const [selectedImage, setSelectedImage] = useState(null); // State for the enlarged image
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const toast = useToast();

  const handleImageClick = (image) => {
    setSelectedImage(image.image_url);
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    toast({
      title: 'Image Load Failed',
      description: 'Some images failed to load.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Image Gallery</ModalHeader>
        <ModalCloseButton />
        <ModalBody>

          {images && images.length > 0 ? (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
              {images.map((image, index) => (
                <Box key={index} onClick={() => handleImageClick(image)}>
                  <Image
                    src={`https://media.arcisai.io:5080/mp4/${image.image_url}`}
                    alt={`Processed Image ${index + 1}`}
                    boxSize="200px"
                    objectFit="contain"
                    borderRadius="8px"
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    cursor="pointer"
                    _hover={{ transform: 'scale(1.05)', transition: '0.3s ease-in-out' }}
                  />
                </Box>
              ))}
            </div>
          ) : (
            <Text>No images available.</Text>
          )}

          {/* Enlarge the selected image */}
          {selectedImage && (
            <Modal isOpen={selectedImage !== null} onClose={() => setSelectedImage(null)} size="full">
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                  <Center>
                    <Image
                    src={`https://media.arcisai.io:5080/mp4/${selectedImage}`}
                      
                      alt="Enlarged Image"
                      maxH="80vh"
                      maxW="90vw"
                      objectFit="contain"
                      borderRadius="8px"
                    />
                  </Center>
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageGalleryModal;



